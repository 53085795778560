
import { defineComponent } from 'vue'
import axios from "axios";
import UserSubscribeItem from '@/components/UserSubscribeItem.vue'

export default defineComponent({
    components:{
        UserSubscribeItem
    },
    setup () {
        return {}
    },
    data(){
        return {
            friends_list: new Array(0),
            recommends_friends_list: new Array(0),
            searchList: new Array(0),
            loading: false,
            friendName: ''
        }
    },
    methods:{
        async loadFriends(){
            try{
                const friends_data = await axios.get(this.$store.state.apiUrl, {
                     params: {
                        type: "User",
                        cmd: "FriendsList",
                    }
                });
                if(friends_data.data.data && friends_data.data.data != false){
                    this.friends_list = friends_data.data.data;
                }

                
            } catch(e: any){
                console.log(e)
            }
        },
        async loadRecommendsFriends(){
            try{
                const friends_data = await axios.get(this.$store.state.apiUrl, {
                     params: {
                        type: "User",
                        cmd: "RecommendsFriendsList",
                    }
                });
                if(friends_data.data.data && friends_data.data.data != false){
                    this.recommends_friends_list = friends_data.data.data;
                }

                
            } catch(e: any){
                console.log(e)
            }
        },
        async findFriends(){
            if(this.friendName.trim().length <= 0) return false;
            this.loading = true;
            try{
                const data = await axios.get(this.$store.state.apiUrl, {
                     params: {
                        type: "User",
                        cmd: "SearchFriendsList",
                        q: this.friendName.trim()
                    }
                });
                if(data.data.data && data.data.data != false){
                    this.searchList = data.data.data;

                    //console.log(data.data.data)
                }

                
            } catch(e: any){
                console.log(e)
            }
            this.loading = false;
        },

        sendFindData(){
            if(this.friendName.trim() == ''){
                this.searchList = new Array(0);
            } else {
                this.findFriends();
            }
        }
    },
    mounted() {
        this.loadFriends();
        this.loadRecommendsFriends();
    },
    /*watch:{
        friendName(newVal, oldVal){
            if(newVal == ''){
                this.searchList = new Array(0);
            }
        }
    },*/
})
