
import { defineComponent } from 'vue'

export default defineComponent({
    setup () {
        return {}
    },
    props:{
        track: {
            default: new Array(0)
        },
        distance: {
            default: 0
        },
        climb: {
            default: 0
        },
        averagespeed: {
            default: 0
        },
        a_temp:{
            default: ''
        }
    },
    data(){
        return {
            tab: null,
            SVGWidth: 1024,
            SVGHeight: 500,
            SVGPaddingLeft: 50,
            SVGPaddingBottom: 25,
            NoElevationData: true,
            ElevatePolyStr: '',
            ElevateLinesH: new Array(0),
            ElevateTextsH: new Array(0),
            ElevateLinesV: new Array(0),
            ElevateTextsV: new Array(0),
            MaxElevate: 0,

            SpeedPolyStr: '',
            SpeedLinesH: new Array(0),
            SpeedTextsH: new Array(0),
            SpeedLinesV: new Array(0),
            SpeedTextsV: new Array(0),
            MaxSpeed: "0",
        }
    },
    methods:{
        drawElevetionGraph(trackArray: any){
            
            let SVGPoints = [this.SVGPaddingLeft+','+(this.SVGHeight-this.SVGPaddingBottom)];
            const point_count = trackArray.length;

            if(point_count < 2 || trackArray[0].length < 4 || this.distance <= 0) return [];
            this.NoElevationData = false;

            let maxHeight = -9999;
            let minHeight = 99999;
            trackArray.forEach((item:any) => {
                maxHeight = Math.max(maxHeight, parseFloat(item[3]));
                minHeight = Math.min(minHeight, parseFloat(item[3]));
            });

            this.MaxElevate = maxHeight;
            maxHeight = Math.ceil(maxHeight/100)*100;
            minHeight = Math.floor(minHeight/100)*100;


            const heightRatio = ((this.SVGHeight-this.SVGPaddingBottom)*0.85)/(maxHeight-minHeight);
            const step = (this.SVGWidth-this.SVGPaddingLeft)/this.distance;

            let now_distance = 0;
            let SVGPointY = 0;

            trackArray.forEach((item:any, i: any) => {
                let difference_distance = 0;
                if(i > 0){
                    difference_distance =this.getDistanceFromLatLonInKm(trackArray[i-1][0],trackArray[i-1][1],item[0],item[1])
                    now_distance += difference_distance
                }
                let SVGPointX = this.SVGPaddingLeft+step*now_distance;

                if(SVGPointY != (this.SVGHeight - (parseFloat(item[3])-minHeight)*heightRatio)-this.SVGPaddingBottom  ){
                    SVGPointY = (this.SVGHeight - (parseFloat(item[3])-minHeight)*heightRatio)-this.SVGPaddingBottom;
                    SVGPoints.push(SVGPointX.toFixed(6)+','+SVGPointY.toFixed(6))
                }
            });

            SVGPoints.push(this.SVGWidth+','+SVGPointY)
            SVGPoints.push(this.SVGWidth+','+(this.SVGHeight-this.SVGPaddingBottom))

            //Суём в svg
            
            const points_str = SVGPoints.join(' ');
            
            this.ElevatePolyStr = points_str;

            this.ElevateLinesH = [];
            this.ElevateTextsH = [];
            //горизонтальную отрисовываем решётку
            let h_line_count = ((maxHeight-minHeight)/1000)%2 == 0 || ((maxHeight-minHeight)/100)%2 == 0 ? 5 : 6;
            for(let h = 1; h <= h_line_count; h++){
                let line_step = (maxHeight-minHeight)/h_line_count;
                let now_height = line_step*h;
                let text = Math.floor(now_height+minHeight)+'м'

                let y_pos = ((this.SVGHeight-this.SVGPaddingBottom) - Math.ceil(now_height*heightRatio))

                this.ElevateLinesH.push({x1:this.SVGPaddingLeft,y1:y_pos,x2:this.SVGWidth,y2:y_pos})
                this.ElevateTextsH.push({x:(this.SVGPaddingLeft-5),y:y_pos,text:text})
            }

            //отрисовываем вертикальную решётку
            this.ElevateLinesV = [];
            this.ElevateTextsV = [];
            let w_line_count = (this.distance/1000)%2 == 0 ||(this.distance/100)%2 == 0 || (this.distance/10)%2 == 0 || this.distance%2 == 0 ? 7 : 8;
            for(let w = 0; w <= w_line_count+1; w++){
                let line_step = Math.ceil(this.distance/w_line_count);
                let now_width = line_step*w;
                let text = now_width+'км'

                let x_pos = this.SVGPaddingLeft+(now_width*step)

                this.ElevateLinesV.push({x1:x_pos,y1:0,x2:x_pos,y2:(this.SVGHeight-this.SVGPaddingBottom)})
                this.ElevateTextsV.push({x:+x_pos,y:(this.SVGHeight-this.SVGPaddingBottom),text:text})
            }
        },
        drawSpeedGraph(trackArray: any){
            let SVGPoints = [this.SVGPaddingLeft+','+(this.SVGHeight-this.SVGPaddingBottom)];
            const point_count = trackArray.length;
            if(point_count < 2 || trackArray[0].length < 3 || this.distance <= 0) return [];
            const step = (this.SVGWidth-this.SVGPaddingLeft)/this.distance;

            let now_distance = 0;
            let now_speed = 0;
            let SVGPointY = 0;

            let lastTime = (new Date(trackArray[0][2])).getTime()/1000;
            let lastDistance = 0;

            let speedArray = new Array(0);
            let maxSpeed = -9999;

            trackArray.forEach((item:any,i:any) => {
                let difference_distance = 0;
                let difference_time = i > 0 ? (new Date(trackArray[i][2])).getTime()/1000 - lastTime : 0;

                if(i > 0){
                    difference_distance = this.getDistanceFromLatLonInKm(trackArray[i-1][0],trackArray[i-1][1],item[0],item[1])
                    now_distance += difference_distance
                }
                let SVGPointX = this.SVGPaddingLeft+step*now_distance;

                if(difference_time >= 5){
                    let tmp_speed = ((now_distance-lastDistance)*1000)/difference_time;
                    now_speed = tmp_speed-now_speed < 10 ? tmp_speed : 0;
                    lastTime = (new Date(trackArray[i][2])).getTime()/1000;
                    lastDistance = now_distance;

                    maxSpeed = Math.max(maxSpeed, (now_speed*3.6));

                    speedArray.push({x:SVGPointX.toFixed(6), y:(now_speed*3.6)})
                }
            });

            this.MaxSpeed = maxSpeed.toFixed(2);

            const speedRatio = ((this.SVGHeight-this.SVGPaddingBottom)*0.75)/maxSpeed;

            speedArray.forEach((item) => {
                SVGPointY = parseFloat(((this.SVGHeight-this.SVGPaddingBottom) - item.y*speedRatio).toFixed(6));
                SVGPoints.push(item.x+','+SVGPointY)
            });

            SVGPoints.push(this.SVGWidth+','+SVGPointY)
            SVGPoints.push(this.SVGWidth+','+(this.SVGHeight-this.SVGPaddingBottom))

            const points_str = SVGPoints.join(' ');
            this.SpeedPolyStr = points_str;
             //отрисовываем вертикальную решётку
            this.SpeedLinesV = [];
            this.SpeedTextsV = [];
            let w_line_count = (this.distance/1000)%2 == 0 ||(this.distance/100)%2 == 0 || (this.distance/10)%2 == 0 || this.distance%2 == 0 ? 7 : 8;
            for(let w = 0; w <= w_line_count+1; w++){
                let line_step = Math.ceil(this.distance/w_line_count);
                let now_width = line_step*w;
                let text = now_width+'км'

                let x_pos = this.SVGPaddingLeft+(now_width*step)

                this.SpeedLinesV.push({x1:x_pos,y1:0,x2:x_pos,y2:(this.SVGHeight-this.SVGPaddingBottom)})
                this.SpeedTextsV.push({x:+x_pos,y:(this.SVGHeight-this.SVGPaddingBottom),text:text})
            }
            //горизонтальную отрисовываем решётку
            this.SpeedLinesH = [];
            this.SpeedTextsH = [];
            let h_line_count = (maxSpeed/1000)%2 == 0 || (maxSpeed/100)%2 == 0 || (maxSpeed/10)%2 == 0 || maxSpeed%2 == 0 ? 5 : 6;
            for(let h = 0; h <= h_line_count; h++){
                let line_step = Math.ceil(maxSpeed/h_line_count);
                let now_speed = line_step*h;
                let text = h == 0 ? 'км/ч' : now_speed;

                if(this.a_temp.length >0){
                    text = h == 0 ? '/км' : this.secondToMinHour(60/now_speed*60);
                }

                let y_pos = ((this.SVGHeight-this.SVGPaddingBottom) - Math.ceil(now_speed*speedRatio))

                this.SpeedLinesH.push({x1:this.SVGPaddingLeft,y1:y_pos,x2:this.SVGWidth,y2:y_pos})
                this.SpeedTextsH.push({x:(this.SVGPaddingLeft-5),y:y_pos,text:text})
            }
        },
        getDistanceFromLatLonInKm(lat1:any,lon1:any,lat2:any,lon2:any){
            lat1 = parseFloat(lat1) / 180 * Math.PI;
            lon1 = parseFloat(lon1) / 180 * Math.PI;
            lat2 = parseFloat(lat2) / 180 * Math.PI;
            lon2 = parseFloat(lon2) / 180 * Math.PI;
            let dlon = lon2 - lon1;
            let dlat = lat2 - lat1;
            let a = Math.sin(dlat/2) * Math.sin(dlat/2) + Math.cos(lat1) * Math.cos(lat2) * Math.sin(dlon/2) * Math.sin(dlon/2);
            let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
            let R = 6371.302;
            return R * c;
        },
        secondToMinHour(seconds: any){
            let seconds_int = parseInt(seconds);
            let seconds_fin = seconds_int%60;
            let mins = Math.floor(seconds_int/60);
            let hours = Math.floor(mins/60);

            mins = mins < 60 ? mins : mins%60;

            let duration_str = (hours > 0 ? hours+":" : "")+this.addFirstZeroToNumb(mins)+":"+this.addFirstZeroToNumb(seconds_fin)
            return duration_str;
        },
        addFirstZeroToNumb(numb: any){
            return numb.toString().length<2 ? "0"+numb : numb;
        },
    },
    mounted() {
        this.drawElevetionGraph(this.track)
        this.drawSpeedGraph(this.track)
    },
})
