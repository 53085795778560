import Vue from 'vue';
import { Store } from 'vuex';

export default class CenteredMapBtn {
    //_satellite: any;
    _map:any;
    _btn: any;
    _container: any;
    _func: any;

    constructor(func:any) {
        this._func = func;
    }

    onAdd(map:any) {
        this._map = map;
        //this._satellite = false; 

        this._btn = document.createElement('button');
        this._btn.className = 'mapboxgl-ctrl-icon';
        this._btn.type = 'button';
        this._btn['aria-label'] = 'Center';

        this._btn.innerHTML = '<span class="mdi mdi-crosshairs-gps"></span>';
        this._btn.onclick = () => { 
            this._func();
        };
        

        this._container = document.createElement('div');
        this._container.className = 'mapboxgl-ctrl mapboxgl-ctrl-group';
        this._container.appendChild(this._btn);

        return this._container;
    }

    onRemove() {
        this._container.parentNode.removeChild(this._container);
        this._map = undefined;
    }

}