
import { defineComponent } from 'vue'
import SettingTab from '@/components/SettingTab.vue'
import UserCard from '@/components/UserCard.vue'
import TrainingList from '@/components/TrainingList.vue'
import UserSubscribeList from '@/components/UserSubscribeList.vue'
import UserSubscribersList from '@/components/UserSubscribersList.vue'
import UserEquipmentsList from '@/components/UserEquipmentsList.vue'

export default defineComponent({
  components:{
    SettingTab,
    UserCard,
    TrainingList,
    UserSubscribeList,
    UserSubscribersList,
    UserEquipmentsList
  },
  setup () {
    return {}
  },
  data(){
    return {
      tab: '',
      update_profile_trigger: false
    }
  },
  methods: {
    updProfile(){
      this.update_profile_trigger = !this.update_profile_trigger;
    }
  },
  mounted() {
    this.$store.dispatch('hiddenlogin');

    if(this.$route.params.tab) this.tab = this.$route.params.tab.toString();
  },
  beforeMount() {
        this.$store.commit("clearTrainings");
  },
  beforeUnmount() {
        this.$store.commit("clearTrainings");
  },
  watch: {
    //отлавливаем изменение адреса для показа нужной вкладки
    $route: {
      handler: function (val, oldVal) {

        if(val !== oldVal &&val.params.tab){
          this.tab = val.params.tab;
        } else {
          this.tab = 'profile';
        }
      },
      deep: true
    },
    //добавляем урл при перелистывании
    tab(val){
      if(val != 'profile'){
        this.$router.push({ name: 'usertab', params: { tab: val} })
      }else {
        this.$router.push({ name: 'profile'})
      }
    }
  }
})
