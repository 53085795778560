
  import { defineComponent } from 'vue';
  import PullRefresh from 'pull-refresh-vue3'
  import TrainingCard from '@/components/TrainingCard.vue'
  import axios from "axios";
  
  // Components
  //import HelloWorld from '../components/HelloWorld.vue';
  
  export default defineComponent({
    name: 'HomeView',
  
    components: {
      TrainingCard,
      PullRefresh
    },
    props: {
        fromUser: {
            default: 0
        }
    },
    data () {
      return {
        loading: false,
        trainingsList: new Array(0),
        minContH: "100vh",
        imgHeight: "",
        bottomload: false,
        endoftrainings: false
      }
    },
    methods: {
      async loadTrainings(lastloaded = -1){
        let loadfrom = lastloaded > 0 ? lastloaded : -1;
        this.loading = true;
        try{
          const trainings_data = await axios.get(this.$store.state.apiUrl, {
            params: {
              type: "Trainings",
              cmd: "GetList",
              loadfrom: loadfrom,
              user: this.fromUser
            }
          });
  
          if(trainings_data.data.data.items && trainings_data.data.data.items.length > 0){
            /*trainings_data.data.data.items.forEach((item: any) => {
              this.trainingsList.push(item)
            });*/
  
            this.$store.commit("addTrainings", trainings_data.data.data.items);
          } else {
            this.endoftrainings = true;
          }
  
        } catch(e: any){
          console.log(e)
        }
        this.loading = false
        this.bottomload = false;
      },
  
      /*setCardHeight(height: any){
        this.minContH = (this.$store.getters.getTrainingsList.length*height)+"px"
      },*/
      scrolling(e:any){
        //localStorage.setItem('trainingsPos', e.target.scrollTop);
      },
      scrollToTraining(tr_id:any){
        const el: any = document.getElementById('training_'+tr_id);
        if(el){
          el.scrollIntoView(/*{behavior: 'smooth'}*/)
        }
      },
      scrollToTop(){
        const el: any = this.$refs.topref;
        if(el){
          el.scrollIntoView(/*{behavior: 'smooth'}*/)
        }
      },
      onRefresh(){
        console.log('refresh')
        this.$store.commit("clearTrainings");
        this.endoftrainings = false;
        this.loadTrainings()
      }
    },
    mounted(){
      this.$store.dispatch('hiddenlogin');
  
      this.imgHeight = ((window.innerWidth-(16*2))*0.75)+"px";
  
      if(localStorage.getItem('trainingsPos')){
        this.scrollToTraining(localStorage.getItem('trainingsPos'))
      } else {
        this.scrollToTop()
        console.log('to top')
        this.$store.commit("clearTrainings");
        this.endoftrainings = false;
        this.loadTrainings()
      }
  
  
      const options = {
          rootMargin: '0px',
          threshold: 1.0
        }
    
        const callback = (entries: any, observer: any) => {
          if(entries[0].isIntersecting){
            if(!this.loading && !this.endoftrainings){
              this.bottomload = true;
              if(this.$store.getters.getTrainingsList[this.$store.getters.getTrainingsList.length-1]){
                this.loadTrainings(this.$store.getters.getTrainingsList[this.$store.getters.getTrainingsList.length-1].id)
              } else {
                this.loadTrainings()
              }
              //console.log("Подгрузка")
            }
          }
        };
    
        const observer = new IntersectionObserver(callback, options);
        observer.observe((this.$refs.scrollpoint as any))
  
        this.$store.commit('setLoadingState', false);
    }
  });
  