
import { defineComponent } from 'vue'
import axios from "axios";
import UserSubscribeItem from '@/components/UserSubscribeItem.vue'

export default defineComponent({
    components:{
        UserSubscribeItem
    },
    data() {
        return {
            opened: false,
            likes_list: new Array(0)
        }
    },
    props:{
        dialog: {
            default: false
        },
        training_id: {
            default:0
        }
    },
    mounted() {
        this.opened = this.dialog
        this.loadLikesList()
    },
    methods:{
        closeDialog(){
            this.opened = false;
            this.$emit('closelikes')
        },
        async loadLikesList(){
            this.$store.commit('setLoadingState', true);
            try{
                const training_likes = await axios.get(this.$store.state.apiUrl, {
                     params: {
                        type: "Training",
                        cmd: "Likes",
                        id: this.training_id
                    }
                });
                if(training_likes.data.data && training_likes.data.data != false){
                    this.likes_list = training_likes.data.data;
                }
            } catch(e: any){
                console.log(e)
            }

            this.$store.commit('setLoadingState', false);
        },
    }
})
