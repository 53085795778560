
import { defineComponent } from 'vue'
import HeaderTitle from '@/components/HeaderTitle.vue'
import TrainingList from '@/components/TrainingList.vue'

export default defineComponent({
  components: {
    HeaderTitle,
    TrainingList
  },
  setup () {
    return {}
  }
})
