import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_tab = _resolveComponent("v-tab")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_tabs = _resolveComponent("v-tabs")!
  const _component_UserCard = _resolveComponent("UserCard")!
  const _component_v_window_item = _resolveComponent("v-window-item")!
  const _component_TrainingList = _resolveComponent("TrainingList")!
  const _component_UserSubscribeList = _resolveComponent("UserSubscribeList")!
  const _component_UserSubscribersList = _resolveComponent("UserSubscribersList")!
  const _component_UserEquipmentsList = _resolveComponent("UserEquipmentsList")!
  const _component_SettingTab = _resolveComponent("SettingTab")!
  const _component_v_window = _resolveComponent("v-window")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_tabs, {
      "show-arrows": "",
      modelValue: _ctx.tab,
      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.tab) = $event)),
      "bg-color": "var(--root-color)",
      color: "var(--first-color)",
      class: "profile_tabs"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_tab, {
          value: "profile",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push({ name: 'profile' })))
        }, {
          default: _withCtx(() => [
            _createTextVNode("Профиль")
          ]),
          _: 1
        }),
        _createVNode(_component_v_tab, {
          value: "trainings",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$router.push({ name: 'usertab', params: { tab: 'trainings' } })))
        }, {
          default: _withCtx(() => [
            _createTextVNode("Тренировки")
          ]),
          _: 1
        }),
        _createVNode(_component_v_tab, {
          value: "subscribe",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$router.push({ name: 'usertab', params: { tab: 'subscribe' } })))
        }, {
          default: _withCtx(() => [
            _createTextVNode("Подписки")
          ]),
          _: 1
        }),
        _createVNode(_component_v_tab, {
          value: "subscribers",
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$router.push({ name: 'usertab', params: { tab: 'subscribers' } })))
        }, {
          default: _withCtx(() => [
            _createTextVNode("Подписчики")
          ]),
          _: 1
        }),
        _createVNode(_component_v_tab, {
          value: "equipments",
          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$router.push({ name: 'usertab', params: { tab: 'equipments' } })))
        }, {
          default: _withCtx(() => [
            _createTextVNode("Снаряжение")
          ]),
          _: 1
        }),
        _createVNode(_component_v_spacer),
        _createVNode(_component_v_tab, {
          value: "setting",
          onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$router.push({ name: 'usertab', params: { tab: 'setting' } })))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_icon, null, {
              default: _withCtx(() => [
                _createTextVNode("mdi-cog")
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_v_window, {
      modelValue: _ctx.tab,
      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.tab) = $event)),
      class: "analysis_tabs"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_window_item, { value: "profile" }, {
          default: _withCtx(() => [
            _createVNode(_component_UserCard, { update_trigger: _ctx.update_profile_trigger }, null, 8, ["update_trigger"])
          ]),
          _: 1
        }),
        _createVNode(_component_v_window_item, { value: "trainings" }, {
          default: _withCtx(() => [
            _createVNode(_component_TrainingList, { fromUser: -1 })
          ]),
          _: 1
        }),
        _createVNode(_component_v_window_item, { value: "subscribe" }, {
          default: _withCtx(() => [
            _createVNode(_component_UserSubscribeList)
          ]),
          _: 1
        }),
        _createVNode(_component_v_window_item, { value: "subscribers" }, {
          default: _withCtx(() => [
            _createVNode(_component_UserSubscribersList)
          ]),
          _: 1
        }),
        _createVNode(_component_v_window_item, { value: "equipments" }, {
          default: _withCtx(() => [
            _createVNode(_component_UserEquipmentsList)
          ]),
          _: 1
        }),
        _createVNode(_component_v_window_item, { value: "setting" }, {
          default: _withCtx(() => [
            _createVNode(_component_SettingTab, {
              onUpdateprofile: _cache[7] || (_cache[7] = ($event: any) => (_ctx.updProfile()))
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}