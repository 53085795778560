
import { defineComponent } from 'vue'
import axios from "axios";
import UserEquipmentItem from '@/components/UserEquipmentItem.vue'

export default defineComponent({
    components:{
        UserEquipmentItem
    },
    setup () {
        return {}
    },
    data(){
        return {
            equipments_list: new Array(0),
            add_dialog: false,
            name: '',
            description: '',
            types:new Array(0),
            type:'',
            rules: [
                (value:any) => {
                    if (value) return true
                    return 'Заполните это поле'
                },
            ],
            loading: false,
            disable_btn: true,

            edit_dialog: false,
            removed: false,
            editId: 0
        }
    },
    methods:{
        async loadEquipments(){
            try{
                const data = await axios.get(this.$store.state.apiUrl, {
                     params: {
                        type: "Equipment",
                        cmd: "Get",
                    }
                });
                if(data.data.data && data.data.data != false){
                    this.equipments_list = data.data.data;
                }

                
            } catch(e: any){
                console.log(e)
            }
        },
        async loadTypes(){
            try{
                const types_data = await axios.get(this.$store.state.apiUrl, {
                     params: {
                        type: "Training",
                        cmd: "Types",
                    }
                });
                if(types_data.data.data && types_data.data.data != false){
                    let types_arr = types_data.data.data;
                    this.types = types_arr;

                    localStorage.setItem('trainingTypes', JSON.stringify(types_arr));
                }
            } catch(e: any){
                console.log(e)
            }
        },
        async saveEquipment(){ 
            
            try {
                const data = await axios.post(this.$store.state.apiUrl,
                    {
                        type: "Equipment",
                        cmd: "Add",
                        name: this.name,
                        description: this.description,
                        ttype: this.type,
                        
                    },
                    {
                        //method: 'PUT',
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        /*onUploadProgress: (progress: any) => {
                            let total = parseFloat(progress.total);
                            let loaded = parseFloat(progress.loaded);
                            let percent = Math.round(loaded/total*100)
                            this.upload_progress = parseInt(percent.toFixed(0));
                        }*/
                    }
                );

                this.loadEquipments();
    
            } catch(e: any){
                console.log(e)
            }
            this.name = '';
            this.type = '';
            this.description = '';

            this.add_dialog = false;
            this.loading = false;
        },
        openEditDialog(editId: any){
            let editedItem = this.equipments_list.filter((equipment) => equipment.ID == editId)[0];

            this.editId = editedItem.ID;
            this.name = editedItem.UF_NAME;
            this.description = editedItem.UF_DESCRIPTION;
            this.type = editedItem.UF_TRAINING_TYPE;
            this.removed = editedItem.UF_REMOVED == 0 || !editedItem.UF_REMOVED ? false : true;

            this.edit_dialog = true;
        },
        async editEquipment(){ 
            try {
                const data = await axios.post(this.$store.state.apiUrl,
                    {
                        type: "Equipment",
                        cmd: "Update",
                        name: this.name,
                        description: this.description,
                        ttype: this.type,
                        removed: this.removed,
                        id: this.editId
                    },
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                    }
                );

                this.loadEquipments();
    
            } catch(e: any){
                console.log(e)
            }
            this.name = '';
            this.type = '';
            this.description = '';

            this.edit_dialog = false;
            this.loading = false;
        },
        checkForm(){
            return this.name.length <= 0 || this.type.length <= 0;
        }
    },
    mounted() {
        this.loadEquipments();
        this.loadTypes();
    },
})
