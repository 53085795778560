
import { defineComponent } from 'vue'

export default defineComponent({
  components: {

  },
  data(){
    return {
        toggle: 0,
    }
  },
  mounted(){
    //console.log(this.path)
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        //console.log(val)
        switch (val.name) {
            case 'record':
                this.toggle = 1;
                break;
            case 'profile':
                this.toggle = 2;
                break;
            case 'usertab':
                this.toggle = 2;
                break;
            default:
                this.toggle = 0;
        }
      },
      deep: true
    },
  }
})
