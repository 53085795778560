import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import RecordView from '../views/RecordView.vue'
import TrainingView from '../views/TrainingView.vue'
import MyProfileView from '../views/MyProfileView.vue'
import ProfileView from '../views/ProfileView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/record/',
    name: 'record',
    component: RecordView
  },
  /*{
    path: '/profile/',
    name: 'profile',
    component: MyProfileView
  },*/
  {
    path: '/profile/',
    name: 'profile',
    component: MyProfileView,
    children: [
      {
        path: ':tab/',
        name:'usertab',
        component: MyProfileView,
      }
    ]
  },
  {
    path: '/user/:id',
    name: 'user',
    component: ProfileView
  },
  {
    path: '/training/id:id',
    name: 'training',
    component: TrainingView
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
