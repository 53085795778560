
import { defineComponent } from 'vue'
import axios from "axios";

export default defineComponent({
    setup () {
        return {}
    },
    data(){
        return {
            commentText: '',
            loading: false,
        }
    },
    props:{
        trainingid:{
            default: 0
        }
    },
    methods:{
        async submit () {
            if(this.commentText.trim().length <= 0) return false;
            this.loading = true;

            try {
                const addComment_data = await axios.post(this.$store.state.apiUrl,
                    {
                        type: "Comments",
                        cmd: "Add",
                        text: this.commentText,
                        training: this.trainingid,
                    }
                );

                if(addComment_data.data && addComment_data.data.data && addComment_data.data.data.id){
                    //успех
                    this.$emit('addcomment', this.commentText)
                    this.commentText = '';
                    this.$emit('reloadtraining')
                }

            } catch(e: any){
                console.log(e)
            }

            this.loading = false;
        }
    }
})
