
import { defineComponent } from 'vue'
import axios from "axios";
declare global {
    interface Window { nativeApp: any; }
}

export default defineComponent({
    setup () {
        return {}
    },
    props: {
        dialog: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            login: '',
            password: '',
            confirmPassword: '',
            email:'',
            rules: [
                (value: any) => {
                    if (value?.trim().length > 5)
                        return true;
                    return "Поле должно содержать минимум 6 символов";
                }
            ],
            regRes: {
                MESSAGE: '',
                TYPE: ''
            }
        }
    },
    methods: {
        checkPasswords(): boolean{
            return this.password === this.confirmPassword;
        },
        checkForm(): boolean{
            return this.login.trim().length > 5 && this.email.trim().length > 5 && this.password.trim().length > 5 && this.confirmPassword.trim().length > 5 && this.checkPasswords();
        },
        async registration() {
            this.$store.commit('setLoadingState', true);
            this.regRes = {
                MESSAGE: "",
                TYPE: "",
            };
            try {
                const reg_data = await axios.post(this.$store.state.apiUrl, {
                    type: "User",
                    cmd: "Register",
                    login: this.login.trim(),
                    pass: this.password.trim(),
                    confirmpass: this.confirmPassword.trim(),
                    email: this.email.trim()
                });
                if (reg_data.data.data.register.TYPE && reg_data.data.data.register.TYPE == "OK") {
                    this.$store.commit("setAuthState", reg_data.data.authorized);
                    //сохраняем логин и пароль
                    if(window.nativeApp){
                      window.nativeApp.saveLoginData(this.login.trim(), this.password.trim());
                    }
                }
                else {
                    this.regRes = reg_data.data.data.register;
                }
            }
            catch (e: any) {
                console.log(e);
            }
            this.$store.commit('setLoadingState', false);
        },
    },
    
})
