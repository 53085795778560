import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6d92b234"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text_muted" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserSubscribeItem = _resolveComponent("UserSubscribeItem")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, { class: "max_width_container" }, {
    default: _withCtx(() => [
      _createElementVNode("h3", null, [
        _createTextVNode("Мои подписчики "),
        _createElementVNode("span", _hoisted_1, " (" + _toDisplayString(_ctx.friends_list.length) + ")", 1)
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.friends_list, (friend) => {
        return (_openBlock(), _createBlock(_component_UserSubscribeItem, {
          friend: friend,
          key: friend.id
        }, null, 8, ["friend"]))
      }), 128))
    ]),
    _: 1
  }))
}