import { createStore } from 'vuex'
import axios from "axios";

export default createStore({
  state: {
    apiUrl: 'https://app.my-dudes.ru/api/',
    version: "0.1.000055",
    authState: false,
    userid: 0,
    recording: Boolean(false),
    nowPoint: String("0&0"),
    recordingPointsStrList: String(),
    recordingPointsListArr: new Array(0),
    globalLoading: Boolean(false),
    globalOffline: Boolean(false),
    trainingsList: new Array(0)
  },
  getters: {
    getAuthState(state){
      return state.authState;
    },
    getUserId(state){
      return state.userid;
    },
    getRecordingStatus(state){
      return state.recording;
    },
    getNowPoint(state){
      return state.nowPoint;
    },
    getNowPointArr(state){
      return state.nowPoint.split("&");
    },
    getRecordingPointsStrList(state){
      return state.recordingPointsStrList;
    },
    getRecordingPointsListArr(state){
      return state.recordingPointsListArr;
    },
    getLoadingState(state){
      return state.globalLoading;
    },
    getOffline(state){
      return state.globalOffline;
    },
    getTrainingsList(state){
      return state.trainingsList;
    },
    //formatTime(state, time: any){
      formatTime: (state) => (time: any) => {
      const now_date = new Date();
      const datetime = new Date(time);
      const year = datetime.getFullYear()
      const month = (datetime.getMonth()+1) < 10 ? "0"+(datetime.getMonth()+1) : (datetime.getMonth()+1);
      const day = datetime.getDate() < 10 ? "0"+datetime.getDate() : datetime.getDate();
      const hour = datetime.getHours() < 10 ? "0"+datetime.getHours() : datetime.getHours();
      const mins = datetime.getMinutes() < 10 ? "0"+datetime.getMinutes() : datetime.getMinutes();

      let str_date = day+"."+month+"."+year;
      const str_time = " в "+hour+":"+mins;
      if(now_date.getFullYear() == datetime.getFullYear() && now_date.getMonth() == datetime.getMonth() && now_date.getDate() == datetime.getDate()){
          str_date = "сегодня"
      } else {
          if(now_date.getFullYear() == datetime.getFullYear() && now_date.getMonth() == datetime.getMonth() && (now_date.getDate()-1 == datetime.getDate())){
              str_date = "вчера"
          }
      }

      return str_date+str_time;
    },
  },
  mutations: {
    setAuthState(state, status: boolean){
      state.authState = status;
    },
    setUserId(state, id: number){
      state.userid = id;
    },
    setRecordingStatus(state, status: boolean){
      state.recording = status;
    },
    setNowPoint(state, point: string){
      state.nowPoint = point;
    },
    setRecordingPointsStrList(state, points: string){
      state.recordingPointsStrList = points;
    },
    setRecordingPointsListArr(state, points: any){
      state.recordingPointsListArr = points;
    },
    updateRecordingPointsListArr(state, points: any){

      state.recordingPointsListArr.push(...points);
    },
    setLoadingState(state, loading: boolean){
      state.globalLoading = loading;
    },
    setOffline(state, off: boolean){
      state.globalOffline = off;
    },
    addTrainings(state, trainings: []){
      trainings.forEach((item)=>{
        state.trainingsList.push(item)
      })
    },
    clearTrainings(state){
      state.trainingsList = [];
    },
    updateTrainingLikes(state, training: any){
      if(training.id && training.id != 0){
        state.trainingsList.forEach((item, index)=>{
          if(item.id == training.id){
            state.trainingsList[index].likes = training.likes;
            state.trainingsList[index].mylike = training.mylike;
          }
        })
      }
    }
  },
  actions: {
    async hiddenlogin({state, commit}){
      if(window.nativeApp){
          try {
              const login_data = await axios.post(state.apiUrl, {
                  type: "User",
                  cmd: "Login",
                  login: window.nativeApp.getLogin(),
                  password: window.nativeApp.getPassword()
              });
              if (login_data.data.data.login === true) {
                  commit("setAuthState", true);
              }
          } catch (e: any) {
              console.log(e);
              if(window.nativeApp.getLogin().length > 0){
                commit("setOffline", true);
              }
          }  
      }
    },
    
  },
  modules: {
  }
})
