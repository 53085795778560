export default class HeatMap {
    _layerIsset: any;
    _map:any;
    _select: any;
    _container: any;
 
    onAdd(map:any) {
        this._layerIsset = false;

        this._map = map;

        const option_group = document.createElement('optgroup');
        option_group.label = "Карта активности"

        const all_option = document.createElement('option');
        all_option.innerHTML = 'Все';
        all_option.value = 'all';

        const ride_option = document.createElement('option');
        ride_option.innerHTML = 'Велосипед';
        ride_option.value = 'ride';

        const run_option = document.createElement('option');
        run_option.innerHTML = 'Бег';
        run_option.value = 'run';

        const winter_option = document.createElement('option');
        winter_option.innerHTML = 'Зимний спорт';
        winter_option.value = 'winter';

        const off_option = document.createElement('option');
        off_option.innerHTML = 'выкл.';
        off_option.value = 'off';
        off_option.selected = true;

        option_group.appendChild(all_option);
        option_group.appendChild(ride_option);
        option_group.appendChild(run_option);
        option_group.appendChild(winter_option);
        option_group.appendChild(off_option);

        this._select = document.createElement('select');
        this._select.appendChild(option_group);
        


        this._select.className = 'mapboxgl-ctrl-icon heatmap_selector';

        this._select.onchange = () => {
            if(this._layerIsset){
                map.removeLayer('overlay_heatmap');
                map.removeSource('overlay_heatmap');
                this._layerIsset = false;
            }

            if(this._select.value !== 'off'){
                map.addSource('overlay_heatmap', {
                    'type': 'raster',
                    //'tiles': ['https://heatmap-external-a.strava.com/tiles/all/hot/{z}/{x}/{y}.png?px=512'],
                    'tiles': ['https://proxy.nakarte.me/https/heatmap-external-a.strava.com/tiles-auth/'+this._select.value+'/hot/{z}/{x}/{y}.png?px=512'],
                    //'tiles': ['https://app.my-dudes.ru/api/heatmaptile.php?z={z}&x={x}&y={y}'],
                    'tileSize': 512,
                    'maxzoom': 15
                });
                map.addLayer({
                    'id': 'overlay_heatmap',
                    'type': 'raster',
                    'source': 'overlay_heatmap',
                });

                this._layerIsset = true;
            }

            map.moveLayer('route');
        }

        this._container = document.createElement('div');
        this._container.className = 'mapboxgl-ctrl mapboxgl-ctrl-group pos_relative';
        this._container.appendChild(this._select);

        return this._container;
    }

    onRemove() {
        this._container.parentNode.removeChild(this._container);
        this._map = undefined;
    }

}