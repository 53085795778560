
import { defineComponent } from 'vue'
import axios from "axios";

export default defineComponent({
    setup () {
        return {}
    },
    props: {
        friend: {
            default: {
                login: '',
                avatar: '',
                city: '',
                id: 0,
                subscribe: undefined
            }
        },
    },
    data(){
        return{
            loading:false,
            i_subscribe: false
        }
    },
    methods: {
        async subscribe() {
            this.loading = true;

            try {
                const subscribe_data = await axios.post(this.$store.state.apiUrl,
                    {
                        type: "User",
                        cmd: "AddFriend",
                        id: this.friend.id,

                    },
                );

            } catch(e: any){
                console.log(e)
            }
            this.loading = false;

            this.i_subscribe = !this.i_subscribe;
        },
        async unSubscribe() {
            this.loading = true;

            try {
                const subscribe_data = await axios.post(this.$store.state.apiUrl,
                    {
                        type: "User",
                        cmd: "DelFriend",
                        id: this.friend.id,

                    },
                );

            } catch(e: any){
                console.log(e)
            }
            this.loading = false;
            this.i_subscribe = !this.i_subscribe;
        },
    },
    mounted() {
        this.i_subscribe = this.friend.subscribe === true ? true : false;
    },
})
