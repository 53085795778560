import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4080c7f0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "comments_block" }
const _hoisted_2 = { class: "text_muted" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CommentItem = _resolveComponent("CommentItem")!
  const _component_v_timeline = _resolveComponent("v-timeline")!
  const _component_CommentAdd = _resolveComponent("CommentAdd")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", null, [
      _createTextVNode("Комментарии "),
      _createElementVNode("span", _hoisted_2, "(" + _toDisplayString(_ctx.comments.length) + ")", 1)
    ]),
    (_ctx.comments.length > 0)
      ? (_openBlock(), _createBlock(_component_v_timeline, {
          key: 0,
          density: "compact",
          side: "end",
          align: "start"
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.comments, (comment) => {
              return (_openBlock(), _createBlock(_component_CommentItem, {
                key: comment.id,
                comment: comment
              }, null, 8, ["comment"]))
            }), 128))
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_CommentAdd, {
      trainingid: _ctx.trainingid,
      onReloadtraining: _ctx.reload,
      onAddcomment: _ctx.addComment
    }, null, 8, ["trainingid", "onReloadtraining", "onAddcomment"])
  ]))
}