
import { defineComponent } from 'vue'
import axios from "axios";

export default defineComponent({
    setup () {
        return {}
    },
    data(){
        return {
            btnloading: false,
            email:'@',
            avatar:'',
            gender:'',
            age:'',
            city:'',
            birthday:'',
            new_avatar: [],
            avatar_img_src: ''
        }
    },
    methods:{
        async loadInfo(id: any){
            try{
                const user_data = await axios.get(this.$store.state.apiUrl, {
                     params: {
                        type: "User",
                        cmd: "Get",
                        year: (new Date()).getFullYear(),
                        id: id
                    }
                });
                if(user_data.data.data && user_data.data.data != false){
                    this.avatar_img_src = user_data.data.data.avatar;
                    this.avatar = user_data.data.data.avatar;
                    this.gender = user_data.data.data.gender && user_data.data.data.gender.length > 0 ? user_data.data.data.gender : '?';
                    //this.age = user_data.data.data.age && user_data.data.data.age > 0 ? user_data.data.data.age : '?';
                    this.city = user_data.data.data.city && user_data.data.data.city.length > 0 ? user_data.data.data.city : '?';
                    this.email = user_data.data.data.email;
                    this.birthday = user_data.data.data.birthday;
                }

                
            } catch(e: any){
                console.log(e)
            }
        },
        async userUpdate () {
            this.btnloading = true;

            try {
                const updateUser_data = await axios.post(this.$store.state.apiUrl,
                    {
                        type: "User",
                        cmd: "Update",
                        gender: this.gender,
                        city: this.city,
                        email: this.email,
                        birthday: this.birthday,
                        file: this.new_avatar
                    },
                    {
                        //method: 'PUT',
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                    }
                );

            } catch(e: any){
                console.log(e)
            }
            this.new_avatar = [];
            this.btnloading = false;

            this.$emit('updateprofile');
        },
        previewImage(event:any){
            let input = event.target;
            let count = input.files.length;
            if(input.files.length > 0){
                this.avatar_img_src = URL.createObjectURL(input.files[0])
            }
        }
    },
    mounted() {
        this.loadInfo(0);
    },
})
