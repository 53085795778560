
import { defineComponent } from 'vue'
import axios from "axios";
declare global {
    interface Window { nativeApp: any; }
}

export default defineComponent({
    setup () {
        return {}
    },
    props: {
        dialog: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            login: '',
            email:'',
            regRes: {
                MESSAGE: '',
                TYPE: ''
            }
        }
    },
    methods: {
        checkForm(): boolean{
            return this.login.trim().length > 5 || this.email.trim().length > 5;
        },
        async forgotPass() {
            this.$store.commit('setLoadingState', true);
            this.regRes = {
                MESSAGE: "",
                TYPE: "",
            };
            try {
                const reg_data = await axios.post(this.$store.state.apiUrl, {
                    type: "User",
                    cmd: "Forgot",
                    login: this.login.trim(),
                    email: this.email.trim()
                });
                if (reg_data.data.data.forgot.TYPE && reg_data.data.data.forgot.TYPE == "OK") {
                    //всё норм
                    this.$emit('close', true);
                }
                else {
                    this.regRes = reg_data.data.data.forgot;
                }
            }
            catch (e: any) {
                console.log(e);
            }
            this.$store.commit('setLoadingState', false);
        },
    },
    
})
