
import { defineComponent } from 'vue'
import axios from "axios";
import CommentItem from '@/components/CommentItem.vue'
import CommentAdd from '@/components/CommentAdd.vue'

export default defineComponent({
    setup () {
        return {}
    },
    components:{
        CommentItem,
        CommentAdd
    },
    /*props: {
        comments: {
            default: [{
                id: 0,
                text: '',
                date: 0
            }]
        }
    },*/
    props: {
        commentsids: {
            default: new Array(0)
        },
        trainingid: {
            default: 0
        }
    },
    data(){
        return {
            comments: new Array(0),
        }
    },
    methods:{
        async loadComments(commentsids: any){
            try{
                const comments_data = await axios.get(this.$store.state.apiUrl, {
                     params: {
                        type: "Comments",
                        cmd: "GetList",
                        ids: commentsids
                    }
                });

                if(comments_data.data.data && comments_data.data.data != false){
                    this.comments = comments_data.data.data;
                }
            } catch(e: any){
                console.log(e)
            }
        },
        reload(){
            this.$emit('reloadtraining')
        },
        addComment(text: any){
            const temp_comment = {
                text: text
            }
            this.comments.push(temp_comment)
        }
    },
    watch: {
        commentsids(newids, oldid){
            console.log(newids)
            if(newids.length > 0){
                this.loadComments(newids)
            }
        }
    }
})
