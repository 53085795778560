
import { defineComponent } from 'vue'

export default defineComponent({
    setup () {
        return {}
    },
    data(){
        return {
            equipment_type: {
                name: '',
                id: '',
                icon: ""
            }
        }
    },
    props: {
        equipment: {
            default: {
                UF_NAME: '',
                icon: '',
                UF_DESCRIPTION: '',
                DISTANTION: 0,
                ID: 0,
                UF_TRAINING_TYPE: 0,
                UF_REMOVED: null
            }
        },
        typeslist: {
            default: new Array(0)
        }
    },
    methods: {
        changeType(){
            let filtered_types = this.typeslist.filter((ttype) => ttype.id == this.equipment.UF_TRAINING_TYPE);
            if(filtered_types.length > 0){
                this.equipment_type = filtered_types[0]
            }
        },
        isRemovedItem(){
            return this.equipment.UF_REMOVED == 0 || !this.equipment.UF_REMOVED ? false : true;
        }
    },
    watch: {
        typeslist: {
            handler: function (val, oldVal) {
                this.changeType()
            },
            deep: true
        }
    },
    mounted() {
        this.changeType()
    },
})
