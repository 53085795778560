import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-470b3d42"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form_fields" }
const _hoisted_2 = { class: "form_fields" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserEquipmentItem = _resolveComponent("UserEquipmentItem")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_toolbar_items = _resolveComponent("v-toolbar-items")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_switch = _resolveComponent("v-switch")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_container, { class: "max_width_container" }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.equipments_list, (equipment) => {
          return (_openBlock(), _createBlock(_component_UserEquipmentItem, {
            onClick: ($event: any) => (_ctx.openEditDialog(equipment.ID)),
            typeslist: _ctx.types,
            equipment: equipment,
            key: equipment.ID
          }, null, 8, ["onClick", "typeslist", "equipment"]))
        }), 128)),
        _createVNode(_component_v_btn, {
          block: "",
          color: "var(--root-color)",
          onClick: _cache[0] || (_cache[0] = ($event: any) => {_ctx.add_dialog = true; _ctx.name = ''; _ctx.type = '';_ctx.description = '';}),
          variant: "outlined"
        }, {
          default: _withCtx(() => [
            _createTextVNode("Добавить")
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_v_dialog, {
      modelValue: _ctx.add_dialog,
      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.add_dialog) = $event)),
      fullscreen: "",
      scrim: false,
      transition: "dialog-bottom-transition",
      persistent: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_form, {
              onSubmit: _cache[6] || (_cache[6] = _withModifiers(() => {}, ["prevent"])),
              class: "add_equipment_form"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_toolbar, {
                  color: "var(--second-color)",
                  class: "dialogToolbar"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_btn, {
                      icon: "",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.add_dialog = false))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_icon, null, {
                          default: _withCtx(() => [
                            _createTextVNode("mdi-close")
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_toolbar_title),
                    _createVNode(_component_v_spacer),
                    _createVNode(_component_v_toolbar_items, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_btn, {
                          loading: _ctx.loading,
                          disabled: _ctx.checkForm(),
                          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.saveEquipment())),
                          variant: "text",
                          type: "submit"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode("Сохранить")
                          ]),
                          _: 1
                        }, 8, ["loading", "disabled"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createElementVNode("div", _hoisted_1, [
                  _createVNode(_component_v_text_field, {
                    modelValue: _ctx.name,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.name) = $event)),
                    variant: "outlined",
                    rules: _ctx.rules,
                    label: "Имя"
                  }, null, 8, ["modelValue", "rules"]),
                  _createVNode(_component_v_textarea, {
                    modelValue: _ctx.description,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.description) = $event)),
                    label: "Описание",
                    variant: "outlined"
                  }, null, 8, ["modelValue"]),
                  _createVNode(_component_v_select, {
                    "item-title": "name",
                    "item-value": "id",
                    modelValue: _ctx.type,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.type) = $event)),
                    label: "Для тренировок с типом",
                    rules: _ctx.rules,
                    items: _ctx.types,
                    variant: "outlined"
                  }, null, 8, ["modelValue", "rules", "items"])
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_v_dialog, {
      modelValue: _ctx.edit_dialog,
      "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.edit_dialog) = $event)),
      fullscreen: "",
      scrim: false,
      transition: "dialog-bottom-transition",
      persistent: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_form, {
              onSubmit: _cache[14] || (_cache[14] = _withModifiers(() => {}, ["prevent"])),
              class: "add_equipment_form"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_toolbar, {
                  color: "var(--second-color)",
                  class: "dialogToolbar"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_btn, {
                      icon: "",
                      onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.edit_dialog = false))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_icon, null, {
                          default: _withCtx(() => [
                            _createTextVNode("mdi-close")
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_toolbar_title, null, {
                      default: _withCtx(() => [
                        _createTextVNode("Редактирование")
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_toolbar_items, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_btn, {
                          loading: _ctx.loading,
                          disabled: _ctx.checkForm(),
                          onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.editEquipment())),
                          variant: "text",
                          type: "submit"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode("Сохранить")
                          ]),
                          _: 1
                        }, 8, ["loading", "disabled"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_v_text_field, {
                    modelValue: _ctx.name,
                    "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.name) = $event)),
                    variant: "outlined",
                    rules: _ctx.rules,
                    label: "Имя"
                  }, null, 8, ["modelValue", "rules"]),
                  _createVNode(_component_v_textarea, {
                    modelValue: _ctx.description,
                    "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.description) = $event)),
                    label: "Описание",
                    variant: "outlined"
                  }, null, 8, ["modelValue"]),
                  _createVNode(_component_v_select, {
                    "item-title": "name",
                    "item-value": "id",
                    modelValue: _ctx.type,
                    "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.type) = $event)),
                    label: "Для тренировок с типом",
                    rules: _ctx.rules,
                    items: _ctx.types,
                    variant: "outlined"
                  }, null, 8, ["modelValue", "rules", "items"]),
                  _createVNode(_component_v_switch, {
                    modelValue: _ctx.removed,
                    "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.removed) = $event)),
                    color: "var(--root-color)",
                    label: "Устарело",
                    "hide-details": ""
                  }, null, 8, ["modelValue"])
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}