import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-28eacc7e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "comment_body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_timeline_item = _resolveComponent("v-timeline-item")!

  return (_openBlock(), _createBlock(_component_v_timeline_item, {
    "dot-color": "var(--root-color)",
    size: "x-small"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["comment_header text_muted", {loading: !_ctx.comment.id}])
      }, [
        _createElementVNode("span", {
          onClick: _cache[0] || (_cache[0] = ($event: any) => {_ctx.$router.push('/user/'+_ctx.comment.authorid);})
        }, _toDisplayString(_ctx.comment.author), 1),
        _createTextVNode(" " + _toDisplayString(_ctx.$store.getters.formatTime(_ctx.comment.date)), 1)
      ], 2),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("p", null, _toDisplayString(_ctx.comment.text), 1)
      ])
    ]),
    _: 1
  }))
}