
import { defineComponent } from 'vue'
import HeaderTitle from '@/components/HeaderTitle.vue'
import UserCard from '@/components/UserCard.vue'
import TrainingList from '@/components/TrainingList.vue'


export default defineComponent({
    components:{
        HeaderTitle,
        UserCard,
        TrainingList
    },
    data() {
        return {
            tab: null,
            subMenuItems: [
                {
                    title: "Подписаться",
                    myonly: false,
                    action: () => { console.log('Subscribed'+this.$route.params.id); this.setUnsubscribeBtn() }
                },

                /*{
                    title: "Удалить",
                    myonly: true,
                    action: function(){console.log(123)}
                },*/
            ],
        }
    },
    beforeMount() {
        this.$store.commit("clearTrainings");
    },
    beforeUnmount() {
        this.$store.commit("clearTrainings");
    },
})
