
  import { defineComponent } from 'vue'
  import RegisterForm from '@/components/login_forms/RegisterForm.vue'
  import ForgotPassForm from '@/components/login_forms/ForgotPassForm.vue'
  import ChangePassForm from '@/components/login_forms/ChangePassForm.vue'
  import axios from "axios";

  declare global {
    interface Window { nativeApp: any; }
  }
  
  
  export default defineComponent({
    name: "LoginForm",
    components: {
      RegisterForm,
      ForgotPassForm,
      ChangePassForm
    },
    data() {
        return {
            valid: true,
            login: "",
            password: "",
            notEmpty: [
                (value: any) => {
                    if (value?.trim().length > 5)
                        return true;
                    return "Поле должно содержать минимум 6 символов";
                }
            ],
            autorizeRes: {
                MESSAGE: "",
                TYPE: "",
            },
            loading: false,
            registerDialog: false,
            forgotDialog: false,
            changePassDialog:false
        };
    },
    methods: {
        async autorize() {
            this.$store.commit('setLoadingState', true);
            this.autorizeRes = {
                MESSAGE: "",
                TYPE: "",
            };
            //const { valid } = await Object(this.$refs.autirize_form).validate();
            try {
                const login_data = await axios.post(this.$store.state.apiUrl, {
                    type: "User",
                    cmd: "Login",
                    login: this.login.trim(),
                    password: this.password.trim()
                });
                if (login_data.data.data.login === true) {
                    this.$store.commit("setAuthState", true);
                    //сохраняем логин и пароль
                    if(window.nativeApp){
                      window.nativeApp.saveLoginData(this.login.trim(), this.password.trim());
                    }
                }
                else {
                    this.autorizeRes = login_data.data.data.login;
                }
            }
            catch (e: any) {
                console.log(e);
            }
            this.$store.commit('setLoadingState', false);
        },
        closeRegister(){
          this.registerDialog = false;
        },
        closeForgot(openChange: any = false){
          this.forgotDialog = false;
          this.changePassDialog = openChange;
        },
        closeChange(){
          this.changePassDialog = false;
        }
    },
    mounted() {
        //this.$router.push('/');
    },
})
  