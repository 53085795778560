
import { defineComponent } from 'vue'
import TrainingCardActionBar from '@/components/TrainingCardActionBar.vue'

export default defineComponent({
    name: 'TrainingCard',

    components:{
      TrainingCardActionBar
    },
    setup () {
        return {}
    },
    props: {
        training: {
            default: {
                name: '',
                image: '',
                user: '',
                type: '',
                date: '',
                id: 0,
                lengthW: "",
                likes: 0,
                mylike: false,
                photocount: 0,
                commentscount: 0
            }
        },
        imageheight: {
          default: ""
        }
    },
    methods:{
      formatTime(time: any){
            let now_date = new Date();
            let datetime = new Date(time);
            let year = datetime.getFullYear()
            let month = (datetime.getMonth()+1) < 10 ? "0"+(datetime.getMonth()+1) : (datetime.getMonth()+1);
            let day = datetime.getDate() < 10 ? "0"+datetime.getDate() : datetime.getDate();
            let hour = datetime.getHours() < 10 ? "0"+datetime.getHours() : datetime.getHours();
            let mins = datetime.getMinutes() < 10 ? "0"+datetime.getMinutes() : datetime.getMinutes();

            let str_date = day+"."+month+"."+year;
            let str_time = " в "+hour+":"+mins;
            if(now_date.getFullYear() == datetime.getFullYear() && now_date.getMonth() == datetime.getMonth() && now_date.getDate() == datetime.getDate()){
                str_date = "сегодня"
            } else {
                if(now_date.getFullYear() == datetime.getFullYear() && now_date.getMonth() == datetime.getMonth() && (now_date.getDate()-1 == datetime.getDate())){
                    str_date = "вчера"
                }
            }

            return str_date+str_time;
        },
        fixNumber(numb: any){
            return parseFloat(numb).toFixed(2)
        },
        toDetailPage(hash: any = ''){
          localStorage.setItem('trainingsPos', this.training.id.toString())
          this.$router.push('/training/id'+this.training.id+hash)
        },
      
    }
})
