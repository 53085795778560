
import { defineComponent } from 'vue'

export default defineComponent({
    setup () {
        return {}
    },
    props: {
        comment:{
            default: {
                id:0,
                text: '',
                date: 0,
                author: 'NAME',
                authorid: 0
            }
        }
    }
})
