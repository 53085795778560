import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c768ac7c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text-h5" }
const _hoisted_2 = {
  key: 2,
  class: "subscribe_btn_cont"
}
const _hoisted_3 = { class: "d-flex param_item" }
const _hoisted_4 = { class: "d-flex param_item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_avatar = _resolveComponent("v-avatar")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_list_subheader = _resolveComponent("v-list-subheader")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_vue_easy_lightbox = _resolveComponent("vue-easy-lightbox")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_container, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_card, {
          loading: _ctx.loading,
          class: "profile_card"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_img, {
              src: _ctx.image,
              height: "100px",
              cover: ""
            }, null, 8, ["src"]),
            (_ctx.avatar.length > 0)
              ? (_openBlock(), _createBlock(_component_v_avatar, {
                  key: 0,
                  size: "80",
                  class: "user_ava",
                  image: _ctx.avatar,
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showImg()))
                }, null, 8, ["image"]))
              : (_openBlock(), _createBlock(_component_v_avatar, {
                  key: 1,
                  color: "brown",
                  size: "80",
                  class: "user_ava"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.name.substring(0, 1).toUpperCase()), 1)
                  ]),
                  _: 1
                })),
            _createVNode(_component_v_card_title, { class: "text-center" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.name), 1)
              ]),
              _: 1
            }),
            (_ctx.$route.params.id)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  (!_ctx.i_subscribe)
                    ? (_openBlock(), _createBlock(_component_v_btn, {
                        key: 0,
                        loading: _ctx.load_subscribe,
                        variant: "flat",
                        color: "var(--root-color)",
                        style: {"color":"var(--first-color)"},
                        size: "small",
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.subscribe()))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("Подписаться")
                        ]),
                        _: 1
                      }, 8, ["loading"]))
                    : (_openBlock(), _createBlock(_component_v_btn, {
                        key: 1,
                        loading: _ctx.load_subscribe,
                        variant: "outlined",
                        color: "var(--root-color)",
                        size: "small",
                        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.unSubscribe()))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("Отписаться")
                        ]),
                        _: 1
                      }, 8, ["loading"]))
                ]))
              : _createCommentVNode("", true),
            _createVNode(_component_v_list, null, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.info_items, (item, i) => {
                  return (_openBlock(), _createBlock(_component_v_list_item, {
                    key: i,
                    color: "primary"
                  }, {
                    prepend: _withCtx(() => [
                      _createVNode(_component_v_icon, {
                        icon: item.icon
                      }, null, 8, ["icon"])
                    ]),
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_3, [
                        _createVNode(_component_v_list_item_title, {
                          textContent: _toDisplayString(item.text)
                        }, null, 8, ["textContent"]),
                        _createVNode(_component_v_spacer),
                        _createElementVNode("p", null, _toDisplayString(item.val), 1)
                      ])
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              ]),
              _: 1
            }),
            _createVNode(_component_v_list, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_list_subheader, null, {
                  default: _withCtx(() => [
                    _createTextVNode("Статистика за " + _toDisplayString((new Date()).getFullYear()), 1)
                  ]),
                  _: 1
                }),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stat_items, (item, i) => {
                  return (_openBlock(), _createBlock(_component_v_list_item, {
                    key: i,
                    color: "primary"
                  }, {
                    prepend: _withCtx(() => [
                      _createVNode(_component_v_icon, {
                        icon: item.icon
                      }, null, 8, ["icon"])
                    ]),
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_4, [
                        _createVNode(_component_v_list_item_title, {
                          textContent: _toDisplayString(item.text)
                        }, null, 8, ["textContent"]),
                        _createVNode(_component_v_spacer),
                        _createElementVNode("p", null, _toDisplayString(item.val), 1)
                      ])
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["loading"])
      ]),
      _: 1
    }),
    _createVNode(_component_vue_easy_lightbox, {
      visible: _ctx.lightBoxVisible,
      imgs: [_ctx.avatar_big],
      index: _ctx.indexRef,
      onHide: _ctx.onHide
    }, null, 8, ["visible", "imgs", "index", "onHide"])
  ], 64))
}