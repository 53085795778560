import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_avatar = _resolveComponent("v-avatar")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item_subtitle = _resolveComponent("v-list-item-subtitle")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, {
    class: _normalizeClass(["mx-auto friend_item", {'removedEq': _ctx.isRemovedItem()}]),
    link: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_actions, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_list_item, { class: "w-100" }, {
            prepend: _withCtx(() => [
              (_ctx.equipment_type.icon)
                ? (_openBlock(), _createBlock(_component_v_avatar, {
                    key: 0,
                    class: "equipment_ava",
                    color: "var(--first-color)",
                    image: _ctx.equipment_type.icon
                  }, null, 8, ["image"]))
                : (_openBlock(), _createBlock(_component_v_avatar, {
                    key: 1,
                    color: "grey-darken-3"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_icon, { icon: "mdi-account-circle" })
                    ]),
                    _: 1
                  }))
            ]),
            default: _withCtx(() => [
              _createVNode(_component_v_list_item_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.equipment.UF_NAME), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_v_list_item_subtitle, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.equipment.DISTANTION) + " км", 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["class"]))
}