
import { defineComponent } from 'vue'
import axios from "axios";
import FooterNav from './components/FooterNav.vue'
import LoginForm from './components/login_forms/LoginForm.vue'
import SynhIcon from './components/SynhIcon.vue'

declare global {
    interface Window { nativeApp: any; updateMapArr: any; }
}

export default defineComponent({
  name: 'App',

  components: {
    FooterNav,
    LoginForm,
    SynhIcon
  },
  data () {
    return {
      //nativeApp: null,
      intervalId: new Number,
      checkerIntervalId: new Number,
      synhInProgress: false,
      hostname: window.location.hostname
    }
  },
  methods: {
    async login(){
      this.$store.commit('setLoadingState', true);
      if(window.nativeApp){
        try {
          const login_data = await axios.post(this.$store.state.apiUrl, {
            type: "User",
            cmd: "Login",
            login: window.nativeApp.getLogin(),
            password: window.nativeApp.getPassword()
          });
          if (login_data.data.data.login === true) {
            this.$store.commit("setAuthState", true);
            this.synhOfflineFileList();
          } else {
            this.$store.commit("setAuthState", false);
          }
        } catch (e: any) {
          console.log(e);
          if(window.nativeApp.getLogin().length > 0){
            this.$store.commit("setOffline", true);
          }
        }  
      }
      this.$store.commit('setLoadingState', false);
      
    },
    async checkOffline(){
      try{
        const autorized_data = await axios.get(this.$store.state.apiUrl, {  });
        //this.$store.commit('setAuthState', autorized_data.data.authorized);
        this.$store.commit("setOffline", false);
        if(this.$store.getters.getAuthState) this.synhOfflineFileList()
      } catch(e: any){
        console.log(e);
        if(window.nativeApp.getLogin().length > 0) this.$store.commit("setOffline", true);
      }
    },
    updateRecordingStatusFromApp(){

      if(window.nativeApp){
        let r_status = window.nativeApp.getRecordStatus() == "true" ? true : false;
        this.$store.commit('setRecordingStatus', r_status);
      } else {
        this.$store.commit('setRecordingStatus', false);
      } 
    },
    updateNowPointFromApp(){
      if(window.nativeApp){
        const native_point = window.nativeApp.getNowPoint();//точка из приложения
        let point_check_arr = native_point.split("&");//разбиваем на координаты

        //если координаты по нулям, то определяем местоположение через вебвью
        if(point_check_arr.length == 2 && parseInt(point_check_arr[0]) == 0 && parseInt(point_check_arr[1]) == 0 ){
          if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition((position) => {
              this.$store.commit('setNowPoint', position.coords.latitude+'&'+position.coords.longitude);
            }, function(error){
              console.log('WebView geolocation error: '+error.code)
            });
          }
        } else {
          this.$store.commit('setNowPoint', native_point);
        }

        
      } else {
        if ("geolocation" in navigator) {
          navigator.geolocation.getCurrentPosition((position) => {
            this.$store.commit('setNowPoint', position.coords.latitude+'&'+position.coords.longitude);
          });
        }
      }
    },
    async updatePointsListFromApp(){
      if(window.nativeApp){
        let json_data = await JSON.parse(window.nativeApp.getPointListJson(this.$store.state.recordingPointsListArr.length))
        for(let i = 0; i < json_data.length; i++){
          let temp_point = json_data[i];
          json_data[i] = [temp_point[1], temp_point[0]]
        }
        this.$store.commit('updateRecordingPointsListArr', json_data)

        //console.log(json_data)
      }
    },
    synhOfflineFileList(){
      if(!window.nativeApp) return false;
      let allfilearr = window.nativeApp.savedFilesList().split("|");
      allfilearr.forEach((item: any) => {
        if(item.length > 5 && item.substr(0,5) == "save_"){
          this.synhInProgress = true;
          this.saveOfflineTrack(item.substr(5,item.length-5))
          //синхронизация тренировки
        }
      });
    },
    async saveOfflineTrack(training_name: any){
      this.synhInProgress = true;
      try {
        const addTraning_data = await axios.post(this.$store.state.apiUrl,
          {
            type: "Training",
            cmd: "AddFromStr",
            training: window.nativeApp.getOfflineTrainingContent(training_name)
          }
        );
        if(addTraning_data.data && addTraning_data.data.data && addTraning_data.data.data.id){
          window.nativeApp.removeOfflineTrainingContent(training_name)
        }
      } catch(e: any){
        console.log(e.toString())
      }
      this.synhInProgress = false;
    },

  },
  mounted(){
    this.login();
    //this.checkAuth();
    this.intervalId = setInterval(()=> {
      this.updateRecordingStatusFromApp();
      this.updateNowPointFromApp();
      this.updatePointsListFromApp()
    }, 1000);

    this.checkerIntervalId = setInterval(()=> {
      this.checkOffline()
      this.synhOfflineFileList()
      this.$store.dispatch('hiddenlogin');
    }, 30000);
   
  },
  unmounted() {
    let intId = parseInt(this.intervalId.toString());
    clearInterval(intId);

    let cintId = parseInt(this.checkerIntervalId.toString());
    clearInterval(cintId);
    localStorage.removeItem('trainingsPos')
  },
  beforeMount(){
    localStorage.removeItem('trainingsPos')
  }

})
