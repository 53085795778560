import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-541493d4"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "justify-self-end"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_avatar = _resolveComponent("v-avatar")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item_subtitle = _resolveComponent("v-list-item-subtitle")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, {
    class: "mx-auto friend_item",
    link: "",
    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$router.push('/user/'+_ctx.friend.id)))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_actions, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_list_item, { class: "w-100" }, {
            prepend: _withCtx(() => [
              (_ctx.friend.avatar.length > 0)
                ? (_openBlock(), _createBlock(_component_v_avatar, {
                    key: 0,
                    color: "grey-darken-3",
                    image: _ctx.friend.avatar
                  }, null, 8, ["image"]))
                : (_openBlock(), _createBlock(_component_v_avatar, {
                    key: 1,
                    color: "grey-darken-3"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_icon, { icon: "mdi-account-circle" })
                    ]),
                    _: 1
                  }))
            ]),
            append: _withCtx(() => [
              (_ctx.friend.subscribe !== undefined)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    (_ctx.i_subscribe)
                      ? (_openBlock(), _createBlock(_component_v_btn, {
                          key: 0,
                          loading: _ctx.loading,
                          onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.unSubscribe()), ["stop"])),
                          variant: "outlined",
                          size: "x-small",
                          color: "var(--root-color)"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode("Отписаться")
                          ]),
                          _: 1
                        }, 8, ["loading"]))
                      : (_openBlock(), _createBlock(_component_v_btn, {
                          key: 1,
                          loading: _ctx.loading,
                          onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.subscribe()), ["stop"])),
                          variant: "tonal",
                          size: "x-small",
                          color: "var(--root-color)"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode("Подписаться")
                          ]),
                          _: 1
                        }, 8, ["loading"]))
                  ]))
                : _createCommentVNode("", true)
            ]),
            default: _withCtx(() => [
              _createVNode(_component_v_list_item_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.friend.login), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_v_list_item_subtitle, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.friend.city), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}