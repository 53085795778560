
import { defineComponent } from 'vue'
import axios from "axios";
import VueEasyLightbox from 'vue-easy-lightbox'

export default defineComponent({
    components:{
        VueEasyLightbox
    },
    data () {
        return {
            loading:false,
            stat_items: [
                { text: 'Время тренировок', icon: 'mdi-clock', val: "" },
                { text: 'Пройденная дистанция', icon: 'mdi-map-marker-path', val: "" },
                { text: 'Набор высоты', icon: 'mdi-image-filter-hdr', val: "" },
            ],
            info_items: [
                { text: 'Пол', icon: 'mdi-account-question', val: "" },
                { text: 'Возраст', icon: 'mdi-clock', val: 0 },
                { text: 'Город', icon: 'mdi-city', val: "" },
            ],
            avatar: '',
            avatar_big: '',
            name: '',
            image: '',
            i_subscribe: false,
            load_subscribe: false,

            imgsRef: new Array(0),
            indexRef: 0,
            lightBoxVisible: false,
        }
    },
    props: {
        update_trigger: {
            default: false
        }
    },
    methods:{
        async loadInfo(id: any){
            this.loading = true;

            try{
                const user_data = await axios.get(this.$store.state.apiUrl, {
                     params: {
                        type: "User",
                        cmd: "Get",
                        year: (new Date()).getFullYear(),
                        id: id
                    }
                });
                if(user_data.data.data && user_data.data.data != false){
                    this.avatar = user_data.data.data.avatar;
                    this.info_items[0].val = user_data.data.data.gender && user_data.data.data.gender.length > 0 ? user_data.data.data.gender : '?';
                    this.info_items[1].val = user_data.data.data.age && user_data.data.data.age > 0 ? user_data.data.data.age : '?';
                    this.info_items[2].val = user_data.data.data.city && user_data.data.data.city.length > 0 ? user_data.data.data.city : '?';

                    this.stat_items[0].val = this.secondToMinHour(user_data.data.data.stat.time);
                    this.stat_items[1].val = user_data.data.data.stat.distantion+"км";
                    this.stat_items[2].val = user_data.data.data.stat.elevation+"м";

                    this.name = user_data.data.data.login;
                    this.image = user_data.data.data.image;
                    this.avatar_big = user_data.data.data.avatar_big;
                    this.i_subscribe = user_data.data.data.i_subscribe;
                }

                
            } catch(e: any){
                console.log(e)
            }

            this.loading = false;
        },
        secondToMinHour(seconds: any){
            let seconds_int = parseInt(seconds);
            let seconds_fin = seconds_int%60;
            let mins = Math.floor(seconds_int/60);
            let hours = Math.floor(mins/60);

            mins = mins < 60 ? mins : mins%60;

            let duration_str = (hours > 0 ? hours+":" : "")+this.addFirstZeroToNumb(mins)+":"+this.addFirstZeroToNumb(seconds_fin)
            return duration_str;
        },
        addFirstZeroToNumb(numb: any){
            return numb.toString().length<2 ? "0"+numb : numb;
        },
        async subscribe() {
            this.load_subscribe = true;

            try {
                const subscribe_data = await axios.post(this.$store.state.apiUrl,
                    {
                        type: "User",
                        cmd: "AddFriend",
                        id: this.$route.params.id,

                    },
                );

            } catch(e: any){
                console.log(e)
            }
            this.load_subscribe = false;

            this.i_subscribe = !this.i_subscribe;
        },
        async unSubscribe() {
            this.load_subscribe = true;

            try {
                const subscribe_data = await axios.post(this.$store.state.apiUrl,
                    {
                        type: "User",
                        cmd: "DelFriend",
                        id: this.$route.params.id,

                    },
                );

            } catch(e: any){
                console.log(e)
            }
            this.load_subscribe = false;
            this.i_subscribe = !this.i_subscribe;
        },
        showImg(){
            this.imgsRef = [this.avatar_big];
            this.indexRef = 0;
            this.lightBoxVisible = true
        },
        onHide(){
            this.lightBoxVisible = false
        },
    },
    mounted() {
        this.loadInfo(this.$route.params.id);
    },
    watch:{
        update_trigger(val){
            this.loadInfo(this.$route.params.id);
        }
    }
})
