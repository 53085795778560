
import { defineComponent } from 'vue'
import axios from "axios";
import TrainingLikesList from '../components/TrainingLikesList.vue';

export default defineComponent({
    components: {
        TrainingLikesList
    },
    props: {
        training: {
            default: {
                name: '',
                image: '',
                user: '',
                type: '',
                date: '',
                id: 0,
                lengthW: "",
                likes: 0,
                mylike: false,
                commentscount: 0
            }
        },
    },
    data() {
        return {
            mylike: false,
            likes: 0,
            show_likes: false
        }
    },
    methods:{
        async likeUnlike(){
            if(this.training.id == 0) return false;

            if(!this.mylike){
                this.likes++;
                this.mylike = true;
            } else {
                this.likes--;
                this.mylike = false;
            }

            this.$store.commit("updateTrainingLikes", {id: this.training.id, likes: this.likes, mylike: this.mylike});

            try{
                const like_data = await axios.post(this.$store.state.apiUrl, {
                    type: "Training",
                    cmd: "LikeUnlike",
                    id: this.training.id,
                });
            } catch(e: any){
                console.log(e)
            }
        }
    },
    mounted() {
        this.mylike = this.training.mylike;
        this.likes = this.training.likes;
    },
})
