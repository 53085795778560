export default class ChangeMapStyle {
    //_satellite: any;
    _map:any;
    _btn: any;
    _container: any;

    onAdd(map:any) {
        this._map = map;
        //this._satellite = false; 

        this._btn = document.createElement('button');
        this._btn.className = 'mapboxgl-ctrl-icon mapboxgl-ctrl-layers';
        this._btn.type = 'button';
        this._btn['aria-label'] = 'Toggle layers';
        this._btn.onclick = () => { 

            if(map.getSource('satellite')){
                map.removeLayer('satellite');
                map.removeSource('satellite');
            } else {
                map.addSource('satellite', {
                    type: 'raster',
                    url: 'mapbox://mapbox.satellite',
                    tileSize: 256
                });
                map.addLayer({
                    'id': 'satellite',
                    'type': 'raster',
                    'source': 'satellite',
                });
            }

            if(map.getSource('overlay_heatmap')){
                map.moveLayer('overlay_heatmap');
            }

            map.moveLayer('route');

            //this._satellite = !this._satellite;

        };
        

        this._container = document.createElement('div');
        this._container.className = 'mapboxgl-ctrl mapboxgl-ctrl-group';
        this._container.appendChild(this._btn);

        return this._container;
    }

    onRemove() {
        this._container.parentNode.removeChild(this._container);
        this._map = undefined;
    }

}