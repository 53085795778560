
import { defineComponent } from 'vue'
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import PitchToggle from '@/plugins/PitchToggle';
import HeatMap from '@/plugins/HeatMap';
import ChangeMapStyle from '@/plugins/ChangeMapStyle';
import CenteredMapBtn from '@/plugins/CenteredMapBtn';
import axios from "axios";



/*import "leaflet/dist/leaflet.css";

import { LMap, LTileLayer, LMarker, LPolyline, LIcon } from "@vue-leaflet/vue-leaflet";
import L from "leaflet";*/

declare global {
    interface Window { map: any; }
}

export default defineComponent({
  name: 'HelloWorld',
  components: {
    //PitchToggle
    /*LMap,
    LTileLayer,
    LMarker,
    LPolyline,
    LIcon*/
  },
  data () {
    return {
      zoom: 17,
      mapCenter: [0, 0],
      nowPointStr: this.$store.getters.getNowPoint,
      stopCentered: false,
      centeredTimer: new Number,
      polylinePoints: [],
      intervalPolyUpdate: new Number,
      onlineIntervalId: new Number,
      
      mapBoxMap: mapboxgl,
      geojsonData: {
        'type': 'Feature',
        'properties': {},
        'geometry': {
          'type': 'LineString',
          'coordinates': []
        }
      },
      mapCenterIcon: mapboxgl,
      mapReading: false,

      usersOnMap: new Array(0)
    }
  },
  mounted() {
    
    //mapboxgl.accessToken = 'pk.eyJ1Ijoib3R2aW50YSIsImEiOiJjbGp6ZDk3enAwZW90M2RydXdwNXNmZmhjIn0.VQSXMSc3k8KJEKLssbpAPQ';
    mapboxgl.accessToken = 'pk.eyJ1Ijoib3R2aW50YSIsImEiOiJjbGwyNjFpMGEwa3VrM2VzN3lrdGRobmFiIn0.qNUj1jzQOMWC90ExX56TUA';
    this.mapBoxMap = new mapboxgl.Map({
      type: 'vector',
      container: 'map',
      zoom: 17,
      style: 'mapbox://styles/mapbox/streets-v12',
    });

    this.mapBoxMap.on('load', () => {
      //рельеф
      this.mapBoxMap.addSource('mapbox-dem', {
          'type': 'raster-dem',
          'url': 'mapbox://mapbox.mapbox-terrain-dem-v1',
          'tileSize': 128,
          'maxzoom': 14
      });
      //this.mapBoxMap.setTerrain({ 'source': 'mapbox-dem', 'exaggeration': 2.5 });

      this.mapBoxMap.addSource('route', {
        'type': 'geojson',
        'data': this.geojsonData
      });
      
      //добавляем путь
      this.mapBoxMap.addLayer({
        'id': 'route',
        'type': 'line',
        'source': 'route',
        'layout': {
          'line-join': 'round',
          'line-cap': 'round'
        },
        'paint': {
          'line-color': 'red',
          'line-width': 5
        }
      });
    });

    //добавляем управление
    this.mapBoxMap.addControl(new mapboxgl.NavigationControl());
    this.mapBoxMap.addControl(new ChangeMapStyle());
    this.mapBoxMap.addControl(new PitchToggle({ }));
    this.mapBoxMap.addControl(new HeatMap());

    const center_func = () => { this.mapBoxMap.panTo([this.$store.getters.getNowPointArr[1], this.$store.getters.getNowPointArr[0]]) };

    this.mapBoxMap.addControl(new CenteredMapBtn( center_func));

    //маркер
    const el = document.createElement('div');
    const width = 30;
    const height = 30;
    el.className = 'marker';
    el.style.backgroundImage = `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23f05455' d='M12,2L4.5,20.29L5.21,21L12,18L18.79,21L19.5,20.29L12,2Z'%3E%3C/path%3E%3C/svg%3E")`;
    el.style.width = `${width}px`;
    el.style.height = `${height}px`;
    el.style.backgroundSize = '100%';
    
    this.mapCenterIcon = new mapboxgl.Marker(el).setLngLat([0,0]).addTo(this.mapBoxMap);
    this.mapCenterIcon.setRotationAlignment('map');

    this.updatePolyline(this.mapBoxMap)
    this.intervalPolyUpdate = setInterval(()=>{this.updatePolyline(this.mapBoxMap)}, 1500);
    //поворот маркера
    window.addEventListener("deviceorientationabsolute", this.rotateCenterIcon);

    let showUsersOnMap = localStorage.getItem("showUsersOnMap") == 'Y';
    if(showUsersOnMap){
      //обновляем друзей на карте
      this.onlineIntervalId = setInterval(()=>{this.loadUsersPos()}, 7000);
      this.loadUsersPos();
    }
  },
  unmounted() {
    window.removeEventListener('deviceorientationabsolute', this.rotateCenterIcon);
    this.mapBoxMap.remove();
    clearInterval(parseInt(this.intervalPolyUpdate.toString()))

    let cintId = parseInt(this.onlineIntervalId.toString());
    clearInterval(cintId);
  },
  methods:{
    //друзья на карте
    updateUsersOnMap(usersArray: any){
      usersArray.forEach((user:any) => {
        let index = this.usersOnMap.findIndex((element) => element.id == user.id)

        const markerHTML = '<b>'+user.login+'</b><br><p>Координаты: '+user.lat+','+user.lon+'<p><p>Скорость: '+user.speed+'км/ч<p><p>Направление: '+this.azimuthDegToText(user.azimuth)+'<p><p class="muted_text">Обновлено в '+this.timestampToFormatText(user.time)+'</p>';
        if(index != -1){
          this.usersOnMap[index].popup.setHTML(markerHTML);
          this.usersOnMap[index].marker.setLngLat([user.lon,user.lat])
        } else {
          let el = document.createElement('div');
          el.className = 'userMarker';
          if(user.avatar && user.avatar.length > 0){
            el.style.backgroundImage = 'url("'+user.avatar+'")';
          } else {
            el.innerHTML = user.login[0];
          }
          el.style.width = '30px';
          el.style.height = '30px';
          el.style.backgroundSize = 'cover';

          const popup = new mapboxgl.Popup({ offset: 15 }).setHTML(markerHTML);
          this.usersOnMap.push({id: user.id, popup: popup, marker: new mapboxgl.Marker(el).setLngLat([user.lon,user.lat]).setPopup(popup).addTo(this.mapBoxMap)});
          
        }
      })
      
      //удаление из массива
      this.usersOnMap.forEach((marker:any) => {
        let index = usersArray.findIndex((element:any) => element.id == marker.id)
        if(index == -1){
          marker.marker.remove();
          this.usersOnMap = this.usersOnMap.filter(item => item.id !== marker.id)
        }
      })

      //console.log(this.usersOnMap)
    },
    clearUsersOnMap(){
      this.usersOnMap.forEach((marker:any) => {
        marker.marker.remove();
      })
      this.usersOnMap = new Array(0)
    },
    stopCenter(){
      if(this.stopCentered == false){
        this.stopCentered = true;
        this.mapCenter = this.$store.getters.getNowPointArr
      } else {
        clearTimeout(parseInt(this.centeredTimer.toString()));
      }
      this.centeredTimer = setTimeout(() => {this.stopCentered = false;}, 10000)
    },
    async updatePolyline(map:any){
        this.geojsonData.geometry.coordinates = this.$store.state.recordingPointsListArr;
        if(this.mapBoxMap.getSource('route')){
          this.mapBoxMap.getSource('route').setData(this.geojsonData);
        }

        this.calcDistance();
      //}

      //console.log(this.$store.getters.getNowPointArr);

      this.mapCenterIcon.setLngLat([this.$store.getters.getNowPointArr[1], this.$store.getters.getNowPointArr[0]]);
      this.updateCenter();
    },
    updateCenter(){
      //console.log(this.$store.getters.getNowPointArr[0])
      if(!this.stopCentered) this.mapBoxMap.panTo([this.$store.getters.getNowPointArr[1], this.$store.getters.getNowPointArr[0]])
    },

    rotateCenterIcon(e: any){
      if(e && e.alpha) this.mapCenterIcon.setRotation(-parseInt(e.alpha));
    },
    calcDistance(){
      let distance = 0;
      if(this.$store.state.recordingPointsListArr.length > 2){
        for(let i = 1; i < this.$store.state.recordingPointsListArr.length; i++){
          distance += this.getDistanceFromLatLonInKm(this.$store.state.recordingPointsListArr[i][1],this.$store.state.recordingPointsListArr[i][0],this.$store.state.recordingPointsListArr[i-1][1],this.$store.state.recordingPointsListArr[i-1][0]);
        }
      }

      this.$emit('distance', distance);
    },
    getDistanceFromLatLonInKm(lat1: any,lon1: any,lat2: any,lon2: any){
        lat1 = parseFloat(lat1) / 180 * Math.PI;
        lon1 = parseFloat(lon1) / 180 * Math.PI;
        lat2 = parseFloat(lat2) / 180 * Math.PI;
        lon2 = parseFloat(lon2) / 180 * Math.PI;
        let dlon = lon2 - lon1;
        let dlat = lat2 - lat1;
        let a = Math.sin(dlat/2) * Math.sin(dlat/2) + Math.cos(lat1) * Math.cos(lat2) * Math.sin(dlon/2) * Math.sin(dlon/2);
        let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
        let R = 6371.302;
        return R * c;
    },
    async loadUsersPos(){
        try{
          const data = await axios.get(this.$store.state.apiUrl, {
            params: {
              type: "User",
              cmd: "OnlineFriendsList",
            }
          });
  
          if(data.data && data.data.data){
            this.updateUsersOnMap(data.data.data)
          } 
  
        } catch(e: any){
          console.log(e)
        }
    },
    
    azimuthDegToText(deg: any){
      let geg_int = parseInt(deg);
      let text_val = 'север';

      if(geg_int <= 22 && geg_int >= 338){
        text_val = 'север';
      }
      if(geg_int > 22 && geg_int <= 67){
        text_val = 'северо-восток';
      }
      if(geg_int > 67 && geg_int <= 112){
        text_val = 'восток';
      }
      if(geg_int > 112 && geg_int <= 157){
        text_val = 'юго-восток';
      }
      if(geg_int > 157 && geg_int <= 202){
        text_val = 'юг';
      }
      if(geg_int > 202 && geg_int <= 247){
        text_val = 'юго-запад';
      }
      if(geg_int > 247 && geg_int <= 292){
        text_val = 'запад';
      }
      if(geg_int > 292 && geg_int <=337 ){
        text_val = 'северо-запад';
      }

      return text_val;
    },

    timestampToFormatText(timestamp: any){
      let date = new Date(timestamp * 1000);
      let hours = date.getHours();
      let minutes = "0" + date.getMinutes();
      let seconds = "0" + date.getSeconds();
      let formattedTime = hours + ':' + minutes.slice(-2) + ':' + seconds.slice(-2);

      return formattedTime;
    }

  },
  watch:{
   
  },

})
