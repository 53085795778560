
import { defineComponent } from 'vue'
import axios from "axios";
import UserSetting from '@/components/UserSettingBlock.vue'

declare global {
    interface Window { nativeApp: any;}
}

export default defineComponent({
    components: {
        UserSetting
    },
    setup () {
        return {}
    },
    data(){
        return {
            items: [
                { text: 'Версия интерфейса', icon: 'mdi-xml', val: this.$store.state.version},
                { text: 'Доступная версия', icon: '', val: '...' },//mdi-check-circle-outline
                { text: 'Версия приложения', icon: 'mdi-cellphone', val: '...' },
                { text: 'Доступная версия', icon: '', val: '...' },//mdi-cellphone
            ],
            frontUpdateBtn: false,
            nativeUpdateBtn: false,
            downloadFrame: false,
            downloadUrl: "https:///my-dudes.ru/downloads/MyDudes.apk",
            checkInProgress: false,

            sendPosition: false,
            showUsersOnMap: false
        }
    },
    methods:{
        async checkVersions(){
            this.checkInProgress = true;

            this.frontUpdateBtn = false;
            this.nativeUpdateBtn = false;
            try{
                const versions_data = await axios.get(this.$store.state.apiUrl+'versions.php', { });
                const front_versions = versions_data.data.frontend;
                const native_versions = versions_data.data.nativeapp;

                if(front_versions[front_versions.length-1].version){
                    this.items[1].val = front_versions[front_versions.length-1].version;
                    if(front_versions[front_versions.length-1].version != this.$store.state.version){
                        this.frontUpdateBtn = true
                    }
                }

                if(native_versions[native_versions.length-1].version){
                    this.items[3].val = native_versions[native_versions.length-1].version;
                    if(native_versions[native_versions.length-1].version != this.items[2].val){
                        this.downloadUrl = "https:///my-dudes.ru/downloads/MyDudes_"+native_versions[native_versions.length-1].version+".apk";
                        this.nativeUpdateBtn = true
                    }
                }

            } catch(e: any){
                console.log(e)
            }
            this.checkInProgress = false;
        },
        updateFrontend(){
            this.$store.commit('setLoadingState', true);
            if(window.nativeApp){
                try {
                    window.nativeApp.updateFront()
                } catch(e:any){
                    console.log(e)
                }
            }
            /*location.reload();
            return false;*/
        },
        updateNative(){
            this.downloadFrame = true;
            setTimeout(()=>{ this.downloadFrame = false; }, 1000)
        },
        saveSendPos() {
            if(window.nativeApp){
                try {
                    if(this.sendPosition){
                        window.nativeApp.saveSendPosState('Y')
                    } else {
                        window.nativeApp.saveSendPosState('N')
                        this.clearMyPos();
                    }
                } catch(e:any){
                    console.log(e)
                }
            }
        },
        setShowUsers(){
            if(this.showUsersOnMap){
                localStorage.setItem('showUsersOnMap','Y')
            } else {
                localStorage.setItem('showUsersOnMap','N')
                
            }
        },
        async clearMyPos() {
            this.$store.dispatch('hiddenlogin');
            try {
                const data = await axios.post(this.$store.state.apiUrl,
                {
                    type: "User",
                    cmd: "NullOnlinePos",
                },
                {
                    headers: {
                    'Content-Type': 'multipart/form-data'
                    },
                }
                );
            } catch(e: any){
                console.log(e)
            }
        },
    },
    mounted() {
        if(window.nativeApp){
            try {
                this.items[2].val = window.nativeApp.getVersion() ? window.nativeApp.getVersion() : this.items[2].val;
            } catch(e:any){
                console.log(e)
            }
        }

        if(window.nativeApp){
            try {
                this.sendPosition = window.nativeApp.getSendPosState() == 'Y' ? true : false;
            } catch(e:any){
                console.log(e)
            }
        }

        this.showUsersOnMap = localStorage.getItem("showUsersOnMap") == 'Y';
        this.checkVersions();  
    },
})
