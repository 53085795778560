
import { defineComponent } from 'vue'
import axios from "axios";
import UserSubscribeItem from '@/components/UserSubscribeItem.vue'

export default defineComponent({
    components:{
        UserSubscribeItem
    },
    setup () {
        return {}
    },
    data(){
        return {
            friends_list: new Array(0),
            recommends_friends_list: new Array(0),
        }
    },
    methods:{
        async loadFriends(){
            try{
                const friends_data = await axios.get(this.$store.state.apiUrl, {
                     params: {
                        type: "User",
                        cmd: "SubscribersList",
                    }
                });
                if(friends_data.data.data && friends_data.data.data != false){
                    this.friends_list = friends_data.data.data;
                }

                
            } catch(e: any){
                console.log(e)
            }
        },
        
    },
    mounted() {
        this.loadFriends();
    },
})
