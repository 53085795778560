
import { defineComponent } from 'vue'
import axios from "axios";

export default defineComponent({
    setup () {
        return {}
    },
    props:{
        dialog: {
            default: false
        },
        trackdata: {
            default: {
                name: "",
                id: 0,
                user: "",
                date: "",
                type: "",
                typenew: {name:'', id:0},
                duration: 0,
                center: {},
                lengthW: 0,
                max_of_w_h: 0,
                zoom: 12,
                alt: 0,
                description: "",
                photos: new Array(0),
                track: new Array(0),
                canEdit: false,
                equipment: ''
            }
        }
    },
    data(){
        return {
            preview_list: new Array(0),
            image_list: new Array(0),
            images_counter: 0,
            delete_images: new Array(0),

            trackName: "",
            trackType:"",
            trackDescription:"",
            trackPhotos: [],
            rules: [
                (value:any) => {
                    if (value) return true
                    return 'Заполните это поле'
                },
            ],
            types: new Array(0),
            loadedPhotos: new Array(0),
            dialogShow: false,
            del_dialog: false,
            progress_dialog: false,
            upload_progress: 0,
            preloadcount: 0,
            equipments_list: new Array(0),
            equipments_list_select: new Array(0),
            equipment: ''
        }
    },
    methods:{
        previewMultiImage(event: any) {
            let input = event.target;
            let count = input.files.length;
            let index = 0;
            if (input.files) {
                while(count --) {
                    const imgid = this.images_counter;

                    this.preloadcount++;

                    let reader = new FileReader();
                    reader.onload = (e:any) => {
                        
                        //this.preview_list.push({id: imgid, src: e.target.result});

                        this.resizeImage(imgid, e.target.result, this.makeFileName(20))
                    }
                    //this.image_list.push({id: imgid, file:input.files[index]});

                    reader.readAsDataURL(input.files[index]);
                    index ++;

                    this.images_counter++;
                }
            }
        },
        async resizeImage(id: any, src: any, filename: any){
            const img_cont = document.getElementById("resize_image_container");
            const img = document.createElement('img');
            if(img && img_cont){
                img.src = src;
                img_cont.appendChild(img);
                img.onload = () =>{
                    const canvas = document.createElement('canvas');
                    canvas.width = img.offsetWidth;
                    canvas.height = img.offsetHeight;

                    const ctx = canvas.getContext("2d");
                    if(ctx) ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

                    canvas.toBlob((blob: any) => {
                        const url = URL.createObjectURL(blob);
                        this.preview_list.push({id: id, src: url});

                        const file = new File([blob], filename+".jpg", { type: "image/jpeg" })

                        this.image_list.push({id: id, file:file});
                        console.log('resize finish')
                        this.preloadcount--;
                        /*newImg.onload = function () {
                            URL.revokeObjectURL(url);//тип освободить память, но пока хз как
                        };*/
  
                    }, 'image/jpeg', 0.85);
                    
                }
            
            }

        },
        makeFileName(length:any) {
            let result = '';
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            const charactersLength = characters.length;
            let counter = 0;
            while (counter < length) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
                counter += 1;
            }
            return result;
        },
        clearImageList(){
            this.images_counter = 0;
            this.preview_list = [];
            this.image_list = [];
        },
        removeImage(id: any){
            const tempFileArr = this.image_list.filter((image) => image.id != id);
            this.image_list = tempFileArr;
            const tempImageArr = this.preview_list.filter((image) => image.id != id);
            this.preview_list = tempImageArr;
        },
        removeLoadedImage(id: any){
            this.loadedPhotos = this.loadedPhotos.filter((image) => image.id != id);
            this.delete_images.push(id)
        },
        async loadTypes(){
            try{
                const types_data = await axios.get(this.$store.state.apiUrl, {
                     params: {
                        type: "Training",
                        cmd: "Types",
                    }
                });
                if(types_data.data.data && types_data.data.data != false){
                    let types_arr = types_data.data.data;
                    this.types = types_arr;

                    localStorage.setItem('trainingTypes', JSON.stringify(types_arr));
                }
            } catch(e: any){
                console.log(e)
            }
        },
        async saveTraining(){  
            //this.$store.commit('setLoadingState', true);
            this.progress_dialog = true;
            try {
                let filearray = [];
                for(let image of this.image_list){
                    filearray.push(image.file);
                }
                const updateTraning_data = await axios.post(this.$store.state.apiUrl,
                    {
                        type: "Training",
                        cmd: "Update",
                        id: this.trackdata.id,
                        name: this.trackName,
                        description: this.trackDescription,
                        trainingtype: this.trackType,
                        files: filearray,
                        delfiles: this.delete_images,
                        timezone: (new Date()).getTimezoneOffset() / 60 *-1,
                        equipment: this.equipment
                    },
                    {
                        //method: 'PUT',
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        onUploadProgress: (progress: any) => {
                            let total = parseFloat(progress.total);
                            let loaded = parseFloat(progress.loaded);
                            let percent = Math.round(loaded/total*100)
                            this.upload_progress = parseInt(percent.toFixed(0));
                        }
                    }
                );   
            } catch(e: any){
                console.log(e)
            }

            //this.clearImageList();
            this.$emit('closedialog')
            //this.$store.commit('setLoadingState', false);
            this.progress_dialog = false;
            this.$emit('reloadtraining');          
        },
        async deleteTraining(){
            this.$store.commit('setLoadingState', true);

            try{
                await axios({
                    url:this.$store.state.apiUrl,
                    method:'DELETE',
                    data : {
                        
                            type: "Training",
                            cmd: "Delete",
                            id: this.trackdata.id
                    }
                })
 
            } catch(e: any){
                console.log(e)
            }
            //this.$store.commit('setLoadingState', false);
            this.del_dialog = false;
            this.$emit('closedialog')
            localStorage.removeItem('trainingsPos')
            this.$store.commit('setLoadingState', false);
            history.back();
        },
        formatTime(time: any){
            let now_date = new Date();
            let datetime = new Date(time);
            let year = datetime.getFullYear()
            let month = (datetime.getMonth()+1) < 10 ? "0"+(datetime.getMonth()+1) : (datetime.getMonth()+1);
            let day = datetime.getDate() < 10 ? "0"+datetime.getDate() : datetime.getDate();
            let hour = datetime.getHours() < 10 ? "0"+datetime.getHours() : datetime.getHours();
            let mins = datetime.getMinutes() < 10 ? "0"+datetime.getMinutes() : datetime.getMinutes();

            let str_date = day+"."+month+"."+year;
            let str_time = " в "+hour+":"+mins;
            if(now_date.getFullYear() == datetime.getFullYear() && now_date.getMonth() == datetime.getMonth() && now_date.getDate() == datetime.getDate()){
                str_date = "сегодня"
            } else {
                if(now_date.getFullYear() == datetime.getFullYear() && now_date.getMonth() == datetime.getMonth() && (now_date.getDate()-1 == datetime.getDate())){
                    str_date = "вчера"
                }
            }

            return str_date+str_time;
        },
        async loadEquipments(){
            try{
                const data = await axios.get(this.$store.state.apiUrl, {
                     params: {
                        type: "Equipment",
                        cmd: "Get",
                    }
                });
                if(data.data.data && data.data.data != false){
                    this.equipments_list = data.data.data;
                }

                
            } catch(e: any){
                console.log(e)
            }

            this.filterEquipments();
        },
        filterEquipments(){
            this.equipments_list_select = this.equipments_list.filter((equipment) => equipment.UF_TRAINING_TYPE == this.trackType)
            /*if(this.equipments_list_select.length > 0){
                this.equipment = this.equipments_list_select[0].ID;
            } else {
                this.equipment = '';
            }*/
        }
    },
    mounted() {
        let types =  localStorage.getItem('trainingTypes');
        if(types != null){
            try{
                this.types = JSON.parse(types);
            }catch(e: any){
                console.log(e)
            }
        }
        
        this.loadTypes();
        this.loadEquipments();
        
    },
    watch: {
        dialog(){
            this.clearImageList()
            this.dialogShow = this.dialog;
            this.delete_images = [];
            this.trackName = this.trackdata.name;
            this.trackDescription = this.trackdata.description;
            this.loadedPhotos = this.trackdata.photos;
            this.trackType = this.trackdata.typenew ? this.trackdata.typenew.id.toString() : "";
            this.equipment = this.trackdata.equipment;
        },
        trackdata(){
            this.trackName = this.trackdata.name;
            this.trackDescription = this.trackdata.description;
            this.loadedPhotos = this.trackdata.photos;
            this.trackType = this.trackdata.typenew ? this.trackdata.typenew.id.toString() : "";
            this.equipment = this.trackdata.equipment;
        },
        trackType(val){
            this.filterEquipments()
        }
    }
})
