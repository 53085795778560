export default class PitchToggle {
    _bearing: any;
    _pitch: any;
    _minpitchzoom: any;
    _map:any;
    _btn: any;
    _container: any;
    
    constructor({bearing = -20, pitch = 70, minpitchzoom = null}) {
        this._bearing = bearing;
        this._pitch = pitch;
        this._minpitchzoom = minpitchzoom;
    }

    onAdd(map:any) {
        this._map = map;
        //const _this = this; 

        this._btn = document.createElement('button');
        this._btn.className = 'mapboxgl-ctrl-icon mapboxgl-ctrl-pitchtoggle-3d';
        this._btn.type = 'button';
        this._btn['aria-label'] = 'Toggle Pitch';
        this._btn.onclick = () => { 
            //map.removeSource('mapbox-dem');
            if (map.getPitch() === 0) {
                //let options = {pitch: _this._pitch, bearing: _this._bearing, zoom: map.getZoom()};
                /*if (_this._minpitchzoom && map.getZoom() > _this._minpitchzoom) {
                    options.zoom = _this._minpitchzoom;
                }*/
                const options = {pitch: this._pitch, bearing: this._bearing};
                map.easeTo(options);
                this._btn.className = 'mapboxgl-ctrl-icon mapboxgl-ctrl-pitchtoggle-2d';

                //рельеф
                try {
                    map.addSource('mapbox-dem', {
                        'type': 'raster-dem',
                        'url': 'mapbox://mapbox.mapbox-terrain-dem-v1',
                        'tileSize': 128,
                        'maxzoom': 14
                    });
                } catch (error) {
                    console.log('sourse exist')
                }
                
                map.setTerrain({ 'source': 'mapbox-dem', 'exaggeration': 2.5 });
            } else {
                map.setTerrain({ 'source': 'mapbox-dem', 'exaggeration': 0 });
                
                map.easeTo({pitch: 0, bearing: 0});
                this._btn.className = 'mapboxgl-ctrl-icon mapboxgl-ctrl-pitchtoggle-3d';
            } 
        };
        

        this._container = document.createElement('div');
        this._container.className = 'mapboxgl-ctrl mapboxgl-ctrl-group';
        this._container.appendChild(this._btn);

        return this._container;
    }

    onRemove() {
        this._container.parentNode.removeChild(this._container);
        this._map = undefined;
    }

}