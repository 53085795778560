import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d4d6208c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "comments_count"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_TrainingLikesList = _resolveComponent("TrainingLikesList")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_card_actions, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_btn, {
          size: "small",
          color: "var(--root-color)",
          variant: "text",
          icon: !_ctx.training.mylike ? 'mdi-heart-outline' : 'mdi-heart',
          onClick: _ctx.likeUnlike
        }, null, 8, ["icon", "onClick"]),
        (_ctx.training.likes > 0)
          ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              class: "likes_count",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.show_likes = !_ctx.show_likes))
            }, _toDisplayString(_ctx.likes), 1))
          : _createCommentVNode("", true),
        _createVNode(_component_v_spacer),
        (_ctx.training.commentscount > 0)
          ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.training.commentscount), 1))
          : _createCommentVNode("", true),
        _createVNode(_component_v_btn, {
          size: "small",
          color: "var(--root-color)",
          variant: "text",
          icon: "mdi-comment-processing-outline",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('tocomments')))
        })
      ]),
      _: 1
    }),
    (_ctx.show_likes)
      ? (_openBlock(), _createBlock(_component_TrainingLikesList, {
          key: 0,
          training_id: _ctx.training.id,
          dialog: _ctx.show_likes,
          onCloselikes: _cache[2] || (_cache[2] = ($event: any) => (_ctx.show_likes = false))
        }, null, 8, ["training_id", "dialog"]))
      : _createCommentVNode("", true)
  ], 64))
}