
import { defineComponent } from 'vue'
import axios from "axios";
import GlobalMap from '../components/GlobalMap.vue';
import TextRecordProblem from '../components/TextRecordProblem.vue';

declare global {
    interface Window { nativeApp: any; }
}

export default defineComponent({

    components: {
        GlobalMap,
        TextRecordProblem
    },
    setup () {
        

        return {}
    },
    data(){
        return {
            preview_list: new Array(0),
            image_list: new Array(0),
            images_counter: 0,


            dialog: false,
            progress_dialog: false,
            dialog_problem: false,
            dialog_del: false,
            trackName: "",
            trackType:'240',
            trackDescription:"",
            trackPhotos: [],
            rules: [
                (value:any) => {
                    if (value) return true
                    return 'Заполните это поле'
                },
            ],
            rulesGPX: [
                (value:any) => {
                    if (value.length > 0) return true
                    return 'Загрузите файл!'
                },
            ],
            rulesFiles: [
                function(value:any) {
                    return !value || !value.length || value[0].size < 15000000 || 'Размер файла не должен превышать 15 MB!'
                },
            ],
            types:new Array(0),
            upload_progress: 0,
            preloadcount: 0,

            loadfromfiledialog: false,
            gpxFile: [],

            difTime: '',
            timeTimer: 0,
            distance: "0",
            equipments_list: new Array(0),
            equipments_list_select: new Array(0),
            equipment: ''
        }
    },
    methods: {
        startStopRecording(){
            if(window.nativeApp){
                if(this.$store.getters.getRecordingStatus == true){
                    this.dialog = true;
                    this.clearMyPos();
                    this.$store.dispatch('hiddenlogin');//this.hiddenlogin();//при нажатии на стоп на всякий случай авторизуем пользователя
                }
                window.nativeApp.startStop();
                this.clearMyPos();
                
            }
        },
        /*saveTrack(){
            window.nativeApp.clearRecord();
            this.dialog = false;
        },*/
        resumeRecord(){
            if(!this.loadfromfiledialog) this.startStopRecording();
            this.loadfromfiledialog = false;
            this.clearImageList()
            this.dialog = false;
            this.trackName = '';
            this.gpxFile = [];
        },
        async saveTrack(){ 
            this.clearMyPos();
            const track_str = window.nativeApp ? window.nativeApp.getPointList(): '';
            if(track_str.length < 10 && this.gpxFile.length < 1){
                return;
            }

            //this.$store.commit('setLoadingState', true);
            this.progress_dialog = true;
            
            localStorage.setItem('lastTrainingType', this.trackType+'');

            try {

                let filearray = [];
                for(let image of this.image_list){
                    filearray.push(image.file);
                }
                const addTraning_data = await axios.post(this.$store.state.apiUrl,
                    {
                        type: "Training",
                        cmd: "Add",
                        name: this.trackName,
                        description: this.trackDescription,
                        trainingtype: this.trackType,
                        track: track_str,
                        files: filearray,
                        gpx: this.gpxFile,
                        timezone: (new Date()).getTimezoneOffset() / 60 *-1,
                        equipment: this.equipment
                    },
                    {
                        //method: 'PUT',
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        onUploadProgress: (progress: any) => {
                            let total = parseFloat(progress.total);
                            let loaded = parseFloat(progress.loaded);
                            let percent = Math.round(loaded/total*100)
                            this.upload_progress = parseInt(percent.toFixed(0));
                        }
                    }
                );

                

                if(addTraning_data.data && addTraning_data.data.data && addTraning_data.data.data.id){
                    if(window.nativeApp)window.nativeApp.clearRecord();
                    this.$store.commit('setRecordingPointsStrList', '')
                    this.$store.commit('setRecordingPointsListArr', [])
                    this.dialog = false;
                    
                    this.$router.push('/training/id'+addTraning_data.data.data.id)
                } else {
                    if(window.nativeApp)window.nativeApp.saveOffline(this.trackName, this.trackDescription, this.trackType);
                    if(window.nativeApp)window.nativeApp.createMessage("Тренировка сохранена оффлайн");
                }
                this.dialog = false;
    
            } catch(e: any){
                console.log(e)
                if(window.nativeApp)window.nativeApp.saveOffline(this.trackName, this.trackDescription, this.trackType);
                if(window.nativeApp)window.nativeApp.createMessage("Тренировка сохранена оффлайн");
                this.dialog = false;
            }

            this.clearImageList();
            this.$store.commit('setRecordingPointsListArr', []);
            //this.$store.commit('setLoadingState', false);

            this.progress_dialog = false;


            //}, 150);//закрываем костыль
           
        },

        /*previewMultiImage(event: any) {
            let input = event.target;
            let count = input.files.length;
            let index = 0;
            if (input.files) {
                while(count --) {
                    const imgid = this.images_counter;

                    let reader = new FileReader();
                    reader.onload = (e:any) => {
                        
                        this.preview_list.push({id: imgid, src: e.target.result});
                    }
                    this.image_list.push({id: imgid, file:input.files[index]});
                    reader.readAsDataURL(input.files[index]);
                    index ++;

                    this.images_counter++;
                }
            }
        },*/
        previewMultiImage(event: any) {
            let input = event.target;
            let count = input.files.length;
            let index = 0;
            if (input.files) {
                while(count --) {
                    const imgid = this.images_counter;
                    this.preloadcount++;

                    let reader = new FileReader();
                    reader.onload = (e:any) => {
                        
                        //this.preview_list.push({id: imgid, src: e.target.result});

                        this.resizeImage(imgid, e.target.result, this.makeFileName(20))
                    }
                    //this.image_list.push({id: imgid, file:input.files[index]});

                    reader.readAsDataURL(input.files[index]);
                    
                    index ++;

                    this.images_counter++;
                }
            }
        },
        async resizeImage(id: any, src: any, filename: any){
            const img_cont = document.getElementById("resize_image_container");
            const img = document.createElement('img');
            if(img && img_cont){
                img.src = src;
                img_cont.appendChild(img);
                img.onload = () =>{
                    const canvas = document.createElement('canvas');
                    canvas.width = img.offsetWidth;
                    canvas.height = img.offsetHeight;

                    const ctx = canvas.getContext("2d");
                    if(ctx) ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

                    canvas.toBlob((blob: any) => {
                        const url = URL.createObjectURL(blob);
                        this.preview_list.push({id: id, src: url});

                        const file = new File([blob], filename+".jpg", { type: "image/jpeg" })

                        this.image_list.push({id: id, file:file});
                        this.preloadcount--;
                        /*newImg.onload = function () {
                            URL.revokeObjectURL(url);//тип освободить память, но пока хз как
                        };*/
  
                    }, 'image/jpeg', 0.85);
                    
                }
            
            }

        },
        makeFileName(length:any) {
            let result = '';
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            const charactersLength = characters.length;
            let counter = 0;
            while (counter < length) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
                counter += 1;
            }
            return result;
        },
        clearImageList(){
            this.images_counter = 0;
            this.preview_list = [];
            this.image_list = [];
        },
        deleteRecordetTraining(){
            this.dialog_del = false;
            if(window.nativeApp)window.nativeApp.clearRecord();
            this.$store.commit('setRecordingPointsStrList', '')
            this.$store.commit('setRecordingPointsListArr', [])
            this.clearImageList()
            this.dialog = false;
            console.log('Delete training')
            this.clearMyPos();
        },
        removeImage(id: any){
            const tempFileArr = this.image_list.filter((image) => image.id != id);
            this.image_list = tempFileArr;

            //console.log(this.preview_list)
            const tempImageArr = this.preview_list.filter((image) => image.id != id);
            this.preview_list = tempImageArr;

            //console.log(tempImageArr)
        },
        async loadTypes(){
            try{
                const types_data = await axios.get(this.$store.state.apiUrl, {
                     params: {
                        type: "Training",
                        cmd: "Types",
                    }
                });
                if(types_data.data.data && types_data.data.data != false){
                    let types_arr = types_data.data.data;
                    this.types = types_arr;

                    localStorage.setItem('trainingTypes', JSON.stringify(types_arr));
                }
            } catch(e: any){
                console.log(e)
            }
        },
        async loadEquipments(){
            try{
                const data = await axios.get(this.$store.state.apiUrl, {
                     params: {
                        type: "Equipment",
                        cmd: "Get",
                    }
                });
                if(data.data.data && data.data.data != false){
                    this.equipments_list = data.data.data;
                }

                
            } catch(e: any){
                console.log(e)
            }

            this.filterEquipments();
        },
        addTrainingFromFile(){
            this.dialog = true;
            this.loadfromfiledialog = true;
            this.$store.dispatch('hiddenlogin');
        },
        setStartTime(){
            localStorage.setItem('trainingStartTime', Date.now().toString());
        },
        setDifTime(){
            const startTime = localStorage.getItem('trainingStartTime') !== null && localStorage.getItem('trainingStartTime') !== undefined ? parseInt(localStorage.getItem('trainingStartTime')!.toString()) : 0;
            if(startTime > 0)this.difTime = this.secondToMinHour(Math.ceil((Date.now()-startTime)/1000));
        },
        secondToMinHour(seconds: any){
            let seconds_int = parseInt(seconds);
            let seconds_fin = seconds_int%60;
            let mins = Math.floor(seconds_int/60);
            let hours = Math.floor(mins/60);

            mins = mins < 60 ? mins : mins%60;

            let duration_str = (hours > 0 ? hours+":" : "")+this.addFirstZeroToNumb(mins)+":"+this.addFirstZeroToNumb(seconds_fin)
            return duration_str;
        },
        addFirstZeroToNumb(numb: any){
            return numb.toString().length<2 ? "0"+numb : numb;
        },
        updateDistance(distance:any){
            this.distance = distance.toFixed(2);
        },
        filterEquipments(){
            this.equipments_list_select = this.equipments_list.filter((equipment) => equipment.UF_TRAINING_TYPE == this.trackType)
            if(this.equipments_list_select.length > 0){
                this.equipment = this.equipments_list_select[0].ID;
            } else {
                this.equipment = '';
            }
        },
        async clearMyPos() {
            this.$store.dispatch('hiddenlogin');

            setTimeout(async () => {
                try {
                    const data = await axios.post(this.$store.state.apiUrl,
                    {
                        type: "User",
                        cmd: "NullOnlinePos",
                    },
                    {
                        headers: {
                        'Content-Type': 'multipart/form-data'
                        },
                    }
                    );

                } catch(e: any){
                    console.log(e)
                }
            }, 1000);
        },
    },
    mounted() {
        this.$store.commit('setLoadingState', false);
        
        let types =  localStorage.getItem('trainingTypes');
        if(types != null){
            try{
                this.types = JSON.parse(types);
            }catch(e: any){
                console.log(e)
            }
        }
        
        this.loadTypes();
        this.loadEquipments();

        let lastType =  localStorage.getItem('lastTrainingType');
        if(lastType != null){
            try{
                this.trackType = lastType;
            }catch(e: any){
                console.log(e)
            }
        }
        this.timeTimer = setInterval(()=>{ this.setDifTime() }, 1000)

        
    },
    unmounted() {
        clearInterval(this.timeTimer);
        if(this.dialog && !this.loadfromfiledialog){
            this.resumeRecord();
        }
    },
    watch: {
        trackType(val){
            this.filterEquipments()
        }
    }
})
